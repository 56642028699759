<template>
  <div id="create-society">
    <card class="pa-4">
      <form >
        <b-form-group label="Nom de la société">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="society.name" placeholder="Indiquez le nom de la société"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Logo de la société">
          <upload-file v-model="society.photo" />
        </b-form-group>
        <div class="center">
          <b-btn @click="createSociety()" append variant="primary">Créer la société</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      society: {}
    };
  },
  methods: {
    async createSociety() {
      try {
        let res = await this.$store.dispatch("createSociety", this.society);
        this.$router.push("/societies");
        this.$store.dispatch("pushNotif", {
          type: "success",
          message: "La société a bien été créee"
        });
      } catch (e) {
        this.$store.dispatch("pushNotif", {
          type: "error",
          message: "Impossible de créer la société"
        });
      }
    }
  },
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "archive",
      text: "Annuler la création",
      type: "error",
      click: () => {
        this.$router.push("/societies");
      }
    });
  }
};
</script>

<style>
</style>
