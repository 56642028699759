import firebase from 'firebase/app'
import 'firebase/storage'

if (!firebase.apps.length) {
    firebase.initializeApp(
        {
            apiKey: "AIzaSyDp8bhuDz2xXaEhXR5lw5dtREFe_TBXu6M",
            authDomain: "etalent-99d4b.firebaseapp.com",
            databaseURL: "https://etalent-99d4b.firebaseio.com",
            projectId: "etalent-99d4b",
            storageBucket: "etalent-99d4b.appspot.com",
            messagingSenderId: "366008124067"
        }
    )
}
const storage = firebase.storage()

export { storage }