<template>
  <div v-if="organization" id="organizations">
    <v-client-table :options="options" name="usersTable" :data="organization.users" :columns="usersColumns">
      <a slot="imgUrl" slot-scope="props">
        <img :src="props.row.imgUrl"/>
      </a>
    </v-client-table>
    <input type="text" v-model="newUser.username">
    <input type="text" v-model="newUser.email">
    <button @click="addUser()">Ajouter un utilisateur à votre organisation</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newUser: {
        email: 'newemail@test.fr',
        username: 'newusername'
      },
      options: {
        headings: {
          username: "Nom d'utilisateur",
          role: "Rôle",
          imgUrl: 'Photo',
          email: "Adresse email"
        },
      },
      usersColumns: ['imgUrl', 'email', 'username', 'role']
    }
  },
  mounted() {
    this.$store.dispatch("fetchOrganization");
  },
  computed: {
    organization() {
      return this.$store.getters.organization();
    },
    user() {
      return this.$store.getters.user();
    }
  },
  methods: {
    addUser() {
      this.$store.dispatch('registerInOrganization', this.newUser)
    }
  }
};
</script>

<style lang="scss">
</style>
