<template>
  <div v-if="candidacy" id="candidacy-infos">
    <ul>
      <li :style="{ backgroundColor: '#999' }">
        {{ createdAt(candidacy.created_at) }}
      </li>
      <li :class="candidacy.state.safeCSSId()">
        {{ candidacy.state }}
      </li>
      <li v-if="candidacy.actions.length > 1">
        <font-awesome-icon class="icon" icon="clock"></font-awesome-icon>
        {{ candidacy.actions[candidacy.actions.length - 1].title }}
      </li>
      <li>
        <font-awesome-icon
          class="icon"
          :icon="[candidacy.favorite ? 'fas' : 'far', 'heart']"
        ></font-awesome-icon>
        <span v-html="candidacy.favorite ? 'Favorite' : 'Non favorite'"></span>
      </li>
      <li :style="{ backgroundColor: '#999' }">
        <router-link
          :to="`/jobs/${candidacy.job.id}/candidacies`"
          v-html="candidacy.job.title"
        ></router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  // dernière action, note moyenne
  props: {
    candidacy: {
      type: Object,
      default: null,
    },
  },
  methods: {
    createdAt(date) {
      return `A postulée ${this.$moment(date).fromNow()}`;
    },
  },
};
</script>

<style lang="scss">
#candidacy-infos {
  > ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > li {
      white-space: nowrap;
      background-color: $primary;
      color: #fff;
      padding: 7px 10px;
      border-radius: 5px;
      margin: 0 5px;
      .icon {
        margin-right: 7px;
      }
      a {
        color: inherit;
      }
    }
  }
}
</style>
