<template>
  <div :style="style" class="card-title" :class="align">
    <h3 :style="titleStyle" class="title">
      <i v-if="icon" :class="icon" class="fas"></i>
      <slot></slot>
    </h3>
    <span v-if="subtitle" class="subtitle" v-html="subtitle"></span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: "Titre par défaut",
      type: String
    },
    align: {
      default: "left",
      type: String
    },
    padding: {
      default: 10,
      type: Number
    },
    icon: {
      default: null,
      type: String
    },
    subtitle: {
      default: null,
      type: String
    },
    color: {
      default: "#676767",
      type: String
    }
  },
  computed: {
    style() {
      return {
        paddingTop: `${this.padding}px`,
        paddingBottom: `${this.padding}px`
      };
    },
    titleStyle() {
      return {
        color: this.color,
        justifyContent: this.align
      };
    }
  }
};
</script>

<style lang="scss">
.card-title {
  padding-left: 5px;
  padding-right: 5px;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  h3 {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin: auto;
    //text-transform: uppercase;
    font-weight: bold;
    font-size:18px;
    i {
      margin-right: 15px;
    }
  }
  span.subtitle {
    display: block;
    margin-top: 7px;
  }
}

@media screen and (max-width: 1000px) {
  .card-title {
    padding: 20px 0 !important;
  }
}
</style>
