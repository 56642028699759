<template>
  <div :class="sidebarStatus ? 'active' : ''" v-if="user" class="sidebar-profile">
    <div :style="{ backgroundImage: `url(${user.imgUrl}`}" @click="$router.push('/profile')" class="user-img">
    </div>
    <div v-if="!sidebarStatus" class="user-info">
      <p v-html="user.username"></p>
      <a @click.prevent="$router.push('/profile')" href="#">Modifier le profil</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: false
    };
  },
  computed: {
    user() {
      return this.$store.getters.user();
    },
    sidebarStatus() {
      return this.$store.getters.sidebarStatus;
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.sidebar-profile {
  background: $primary;
  position: relative;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  box-shadow: $box-shadow;
  &.active {
    .user-img {
      width: 45px;
      height: 45px;
    }
  }
  .user-img {
    cursor: pointer;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.4);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    margin-right: 10px;
  }
  .user-info {
    color: #fff;
    text-align: left;
    line-height: 1.4;
    p {
      margin:0;
      color:#fff;
    }
    a {
      text-decoration: none;
      color: #fff;
      opacity: 0.4;
      transition: opacity 0.1s;
      font-size: 80%;
      &:hover {
        opacity: 8;
      }
    }
  }
}
</style>
