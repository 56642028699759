<template>
  <div id="users">
    <v-client-table v-if="users" class="etalent-table" :options="options" name="usersTable" :data="users" :columns="columns">
      <div slot="username" slot-scope="props">
        <div class="has-avatar">
          <div class="table-avatar" :style="setBg(props.row.imgUrl)"></div>
          <span v-html="props.row.username"></span>
        </div>
      </div>
      <div slot="role" slot-scope="props">
        {{displayRole(props.row)}}
      </div>
      <ul v-if="props.row.id != user.id" class="tools" slot="tools" slot-scope="props">
        <li v-tooltip="'Supprimer cet utilisateur'">
          <font-awesome-icon @click="deleteUser(props.row.id)" icon="trash-alt"></font-awesome-icon>
        </li>
      </ul>
    </v-client-table>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import DELETE_USER from "@/api/mutations/User/deleteUser.graphql";
import Vue from "vue";
export default {
  data() {
    return {
      selectedJobs: {},
      options: {
        filterByColumn: true,
        filterable: ["username", "name", "firstname", "email", "role"],
        listColumns: {
          role: [
            {
              text: "Administrateur",
              value: "ADMIN"
            },
            {
              text: "Directeur de société",
              value: "DIRECTOR"
            },
            {
              text: "Manager de département",
              value: "MANAGER"
            },
            {
              text: "Utilisateur classique",
              value: "USER"
            }
          ]
        },
        headings: {
          imgUrl: "Photo de profil",
          email: "Adresse email",
          username: "Nom d'utilisateur",
          role: "Rôle",
          tools: "Outils",
          firstname: "Prénom",
          name: "Nom"
        }
      }
    };
  },
  computed: {
    columns() {
      return [
        "username",
        "firstname",
        "name",
        "email",
        "role",
        ...(this.isAdmin ? ["tools"] : [])
      ];
    },
    users() {
      return this.$store.getters.users();
    },
    user() {
      return this.$store.getters.user();
    },
    isAdmin() {
      return this.$store.getters.isAdmin();
    }
  },
  methods: {
    displayRole(user) {
      let role = user.role.toUpperCase();
      if (role == "DIRECTOR") {
        return role + ` de la société ${user.society.name}`;
      }
      if (role == "MANAGER") {
        return role + ` du département ${user.department.name}`;
      }
      return role;
    },
    deleteUser(id) {
      client
        .mutate({
          mutation: DELETE_USER,
          variables: {
            id
          }
        })
        .then(res => {
          this.$store.dispatch("fetchUsers");
          this.$store.dispatch("pushNotif", {
            type: "success",
            message: "L'utilisateur a bien été supprimé"
          });
        })
        .catch(err => {
          this.$store.dispatch("pushNotif");
        });
    }
  },
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "plus-square",
      roles: ["admin"],
      text: "Créer un nouvel utilisateur",
      click: () => {
        this.$router.push("/create/user");
      }
    });
    this.$store.dispatch("fetchUsers");
  }
};
</script>

<style lang="scss">
</style>
