<template>
  <div v-if="candidacy" id="history">
    <card>
      <template slot="header">
        <font-awesome-icon class="icon" icon="history"></font-awesome-icon>
        <h2>Historique d'actions </h2>
        <div>
          <ActionForm :candidacy="candidacy" />
        </div>
      </template>
      <div class="actions-list" v-bar>
        <ul class="list">
          <li v-for="action in candidacy.actions" :key="action.id">
            <div class="avatar" :style="setBg(action.user.imgUrl)"></div>
            <div class="item">
              <p>{{action.user.username}} a réalisé l'action <strong>{{action.title}}</strong></p>
              <span class="subinfo">{{action.created_at | moment("from", "now")}}</span>
            </div>
            <div v-if="action.user.id === user.id">
              <a v-tooltip="'Supprimer l\'action'" href="#" @click.prevent="deleteAction(action.id)" class="trash-action">
                <font-awesome-icon class="icon" icon="trash"></font-awesome-icon>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import DELETE_ACTION from "@/api/mutations/Action/deleteAction.graphql";
import ActionForm from "@/components/Candidacy/ActionForm";
export default {
  components: { ActionForm },
  props: {
    candidacy: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      id: this.$route.params.candidacyId
    }
  },
  computed: {
    user() {
      return this.$store.getters.user();
    }
  },
  methods: {
    async deleteAction(id) {
      let res = await client.mutate({
        mutation: DELETE_ACTION,
        variables: { id }
      });
      this.$store.dispatch("fetchCandidacy", this.id);
    }
  }
};
</script>

<style lang="scss">
.actions-list {
  height: 300px;
}
.trash-action {
  color: $error;
  &:hover {
    color: $error-D;
  }
}
</style>
