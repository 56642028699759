<template>
  <div id="latest-actions">
    <card>
      <template slot="header">
        <font-awesome-icon class="icon" icon="comments"></font-awesome-icon>
        <h2>Dernières actions</h2>
      </template>
      <ul class="list">
        <li v-for="(action, i) in latestActions" :key="i">
          <div v-if="action.candidacy">
            <div>
              <Avatar v-if="action.user.imgUrl" :img="action.user.imgUrl" />
            </div>
            <div>
              <p> {{action.title}} sur la candidature de <router-link v-html="action.candidacy.name"
                  :to="`/candidacies/${action.candidacy.id}`"></router-link>
              </p>
              <p class="subinfo">{{action.created_at | moment("from", "now")}}</p>
            </div>
            <div class="right">
              <router-link :to="`/jobs/${action.candidacy.job.id}`" v-html="action.candidacy.job.title"></router-link>
            </div>
          </div>

        </li>
      </ul>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import LATEST_ACTIONS from "@/api/queries/Stats/latestActions.graphql";
export default {
  data() {
    return {
      latestActions: null
    };
  },
  methods: {
    async fetchLatestActions() {
      let res = await client.query({
        query: LATEST_ACTIONS
      });
      this.latestActions = res.data.latestActions;
    }
  },
  mounted() {
    this.fetchLatestActions();
  }
};
</script>

<style lang="scss">

</style>
