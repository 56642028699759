import Vue from "vue";

const defaultState = {
    notifications: {

    }
};

const actions = {
    pushNotif: (context, notif) => {
        if(notif == undefined) {
            notif = {
                type: "error",
                message: "Une erreur est survenue. Merci de contacter l'administrateur."
            }
        }
        let key = Date.now();
        notif.key = key
        notif.opacity = 1;
        context.commit('SET_NOTIF', notif)
        setTimeout(() => {
            context.commit('HIDE_NOTIF', notif.key)
        }, 3000);
        setTimeout(() => {
            context.commit('UNSET_NOTIF', notif.key)
        }, 5000);

    },
};

const mutations = {
    SET_NOTIF: (state, notif) => {
        Vue.set(state.notifications, notif.key, notif);
    },
    UNSET_NOTIF: (state, key) => {
        Vue.delete(state.notifications, key)
    },
    HIDE_NOTIF: (state, key) => {
        Vue.set(state.notifications[key], 'opacity', 0)
    }
};

const getters = {
    notifications: state => () => state.notifications,
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
