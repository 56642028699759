<template>
  <div class="reset-password">

    <form @submit.prevent="resetPassword()">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="user"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input type="password" v-model="form.password" placeholder="Indiquez votre nouveau mot de passe" required></b-form-input>
      </b-input-group>
      <Spacer :lvl="2" />
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="user"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input type="password" v-model="form.confirmPassword" placeholder="Confirmez votre nouveau mot de passe" required></b-form-input>
      </b-input-group>
      <div v-if="checkForm()" class="my-4 flex">
        <Btn v-if="!success" class="mx-3" shadow radius="4px" padding="18px 30px" @click.native="resetPassword()">
          <span>Changer mon mot de passe</span>
        </Btn>
      </div>

      <div v-else>
        <Spacer :lvl="2" />
        Votre nouveau de passe n'est pas conforme. Merci de remplir les 2 champs.
      </div>
      <div v-if="success">Votre mot de passe a bien été modifié.</div>
      <div v-html="error" class="error" v-if="error"></div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      success: false,
      token: this.$route.params.token,
      form: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  methods: {
    checkForm() {
      return (
        this.form.password.length > 10 &&
        this.form.password === this.form.confirmPassword
      );
    },
    resetPassword() {
      this.$store
        .dispatch("resetPassword", {
          password: this.form.password,
          token: this.token
        })
        .then(res => {
          this.success = true;
          this.error = false;
          setTimeout(() => {
            this.$router.push('/login')
          },2000)
        })
        .catch(err => {
          console.log(err);
          this.error = err;
          this.success = false;
        });
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
</style>
