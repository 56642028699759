<template>
  <div class="notifications">
    <ul>
      <li :class="notif.type" :style="notifStyle(notif)" v-for="(notif, key) in notifications" :key="key">
        <font-awesome-icon @click="removeNotif(key)" class="remove-notif" icon="times"></font-awesome-icon>
        <span v-html="notif.message"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  computed: {
    notifications() {
      return this.$store.getters.notifications();
    }
  },
  methods: {
    notifStyle(notif) {
      return {
        opacity: notif.opacity
      };
    },
    removeNotif(key) {
      this.$store.commit("HIDE_NOTIF", key);
    }
  }
};
</script>

<style lang="scss">
.notifications {
  position: fixed;
  right: 15px;
  bottom: 10px;
  z-index: 3333;
  width: 500px;
  max-width: 70%;
  > ul {
    > li {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.4s;
      border-radius: 3px;
      padding: 15px 22px;
      position: relative;
      margin-bottom: 10px;
      text-align: left;
      width: 100%;
      .remove-notif {
        position: absolute;
        right: 6px;
        top: 6px;
        color: #fff;
        font-size: 13px;
      }
      background-color:$info;
      &.success {
        background-color: $success;
      }
      &.error {
        background-color: $error;
      }
      &.warning {
        background-color: $warning;
      }
      &.info {
        background-color: $info;
      }
      span {
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
</style>
