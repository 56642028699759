const defaultState = {
    loading:0
};

const actions = {
};

const mutations = {
    UPDATE_LOADING: (state, payload) => {
        state.loading += payload
    }
};

const getters = {
    isLoading: state => state.loading > 0,
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
