import Vue from "vue";
import VueTables from 'vue-tables-2';
Vue.use(VueTables.ClientTable, {
    useVuex: true,
    theme: "bulma",
    texts: {
        filterPlaceholder: "Recherche ...",
        count: "{count} élément(s)",
        first: 'Premier',
        last: 'Dernier',
        filter: "Filtres:",
        page: "Page:",
        noResults: "Aucun élément correspondant",
        filterBy: "Filtrer par {column}",
        loading: 'Chargement ...',
        columns: 'Colonnes',
        defaultOption:'Séléctionner par {column}'
    },
});