<template>
  <div class="loading-indicator">
    <font-awesome-icon class="icon" icon="spinner" spin></font-awesome-icon>
  </div>
</template>

<script>
export default {
  computed: {}
};
</script>

<style lang="scss">
.loading-indicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #edefed;
  z-index: 22;
  display:flex;
  //align-items: center;
  justify-content: center;
  padding-top:35vh;
  .icon {
    color: $text;
    font-size:35px;
  }
}
</style>
