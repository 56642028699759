<template>
  <div id="candidacy-nav">
    <ul>
      <li :style="{visibility: previous ? 'visibile' : 'hidden'}" @click="$router.push(`/candidacies/${previous}`)">
        <font-awesome-icon class="icon" icon="arrow-left"></font-awesome-icon>
        Candidacy précédente
      </li>
      <li v-show="next" @click="$router.push(`/candidacies/${next}`)">
        <font-awesome-icon class="icon" icon="arrow-right"></font-awesome-icon>
        Candidacy suivante
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    previous: {
      type: Number,
      default: null
    },
    next: {
      type: Number,
      default: null
    }
  }
};
</script>

<style lang="scss">
#candidacy-nav {
  padding: 0 20px;
  > ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      .icon {
        margin: 0 5px;
      }
    }
  }
}
</style>