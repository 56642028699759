import Vue from 'vue'
import Vuex from 'vuex'


import auth from './modules/auth.js'
import users from './modules/users.js'
import societies from './modules/societies.js'
import jobs from './modules/jobs.js'
import loading from './modules/loading.js'
import app from './modules/app.js'
import candidacies from './modules/candidacies.js'
import organization from './modules/organization.js'
import notifications from './modules/notifications.js';
import folders from './modules/folders.js';
import tags from './modules/tags.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth, users, societies, jobs, loading, app, candidacies, organization, notifications, folders, tags
    },
});

export default store