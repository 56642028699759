import { client } from '@/apollo/init.js';

import SOCIETIES from "@/api/queries/Society/societies.graphql";
import SOCIETY from "@/api/queries/Society/society.graphql";
import DEPARTMENT from "@/api/queries/Society/department.graphql";

import CREATE_SOCIETY from "@/api/mutations/Society/createSociety.graphql";
import CREATE_DEPARTMENT from "@/api/mutations/Society/createDepartment.graphql";
import DELETE_SOCIETY from "@/api/mutations/Society/deleteSociety.graphql";
import DELETE_DEPARTMENT from "@/api/mutations/Society/deleteDepartment.graphql";
import UPDATE_SOCIETY from "@/api/mutations/Society/updateSociety.graphql";
import UPDATE_DEPARTMENT from "@/api/mutations/Society/updateDepartment.graphql";

const defaultState = {
    societies: null,
    society: null,
    department: null
};

const actions = {
    fetchSocieties: (context) => {
        return client.query({
            query: SOCIETIES
        }).then(res => {
            context.commit('SET_SOCIETIES', res.data.societies)
        }).catch(e => {
            console.log(e)
        })
    },
    fetchSociety: (context, id) => {
        return client.query({
            query: SOCIETY,
            variables: {
                id
            }
        }).then(res => {
            context.commit('SET_SOCIETY', res.data.society)
        }).catch(e => {
            console.log(e)
        })
    },
    fetchDepartment: (context, id) => {
        return client.query({
            query: DEPARTMENT,
            variables: {
                id
            }
        }).then(res => {
            context.commit('SET_DEPARTMENT', res.data.department)
        }).catch(e => {
            console.log(e)
        })
    },
    createSociety: (context, society) => {
        return client.mutate({
            mutation: CREATE_SOCIETY,
            variables: {
                societyInput: society
            }
        }).then(res => {
            context.dispatch('fetchSocieties')
            return res
        }).catch(e => {
            throw e;
        })
    },
    createDepartment: (context, { societyId, departmentInput }) => {
        return client.mutate({
            mutation: CREATE_DEPARTMENT,
            variables: {
                societyId: societyId,
                departmentInput: departmentInput
            }
        }).then(res => {
            return res.data.createDepartment.ok
        }).catch(err => {
            return false
        })
    },
    deleteSociety: (context, societyId) => {
        return client.mutate({
            mutation: DELETE_SOCIETY,
            variables: {
                societyId: societyId
            }
        }).then(res => {
            context.dispatch('fetchSocieties')
        })
    },
    deleteDepartment: (context, { id, societyId }) => {
        return client.mutate({
            mutation: DELETE_DEPARTMENT,
            variables: {
                id
            }
        }).then(res => {
            context.dispatch('fetchSociety', societyId)
        })
    },
    updateSociety: (context, society) => {
        let { name, photo } = society
        return client.mutate({
            mutation: UPDATE_SOCIETY,
            variables: {
                societyInput: {
                    name, photo
                },
                societyId: society.id
            }
        })
    },
    updateDepartment: (context, department) => {
        let { name } = department
        return client.mutate({
            mutation: UPDATE_DEPARTMENT,
            variables: {
                departmentInput: {
                    name
                },
                departmentId: department.id
            }
        }).then(res => {
            return res.data.updateDepartment.ok
        }).catch(err => {
            return false
        })
    }
};

const mutations = {
    SET_SOCIETIES: (state, societies) => {
        state.societies = societies
    },
    SET_SOCIETY: (state, society) => {
        state.society = society
    },
    SET_DEPARTMENT: (state, department) => {
        state.department = department
    }
};

const getters = {
    societies: state => () => state.societies,
    society: state => (id) => state.society,
    department: state => (id) => state.department,
    allDepartments: state => () => {
        if (state.societies) {
            var departments = []
            state.societies.map((society) => {
                departments = departments.concat(society.departments)
            })
            return departments
        }
        return []
    },
    societiesOptions: state => () => {
        if (state.societies) {
            return state.societies.map((society) => {
                return {
                    text: society.name,
                    value: society.id
                }
            })
        }
    },
    departmentsOptions: state => (societyId) => {
        if (state.societies) {
            let society = state.societies.find(society => society.id === societyId);
            if (society) {
                return society.departments.map((department) => {
                    return {
                        text: department.name,
                        value: department.id
                    }
                })
            }

        }
    }
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
