<template>
  <div id="board-header">
    <MobileNavbar />
  </div>
</template>

<script>
import MobileNavbar from "@/components/Board/MobileNavbar";
export default {
  components: {
    MobileNavbar
  },
  props: {
  },
  computed: {
    user() {
      return this.$store.getters.user();
    }
  },
  methods: {}
};
</script>

<style lang="scss">
</style>
