import Candidacy from '@/views/Candidacies/Candidacy.vue'
import Candidacies from '@/views/Candidacies/Candidacies.vue'
import CreateCandidacy from '@/views/Candidacies/Create.vue'
import EditCandidacy from '@/views/Candidacies/Edit.vue'

const routes = [
    {
        path: '/jobs/:id/candidacies',
        name: 'job candidacies',
        component: Candidacies,
        meta: {
            title: 'Candidatures',
            appTitle: 'Candidatures',
            requiresAuth: true
        }
    },
    {
        path: '/candidacies',
        name: 'all candidacies',
        component: Candidacies,
        meta: {
            title: 'Candidatures',
            appTitle: 'Candidatures',
            requiresAuth: true
        }
    },
    {
        path: '/candidacies/:candidacyId',
        name: 'candidacy',
        component: Candidacy,
        meta: {
            title: 'Candidature',
            appTitle: 'Candidature',
            requiresAuth: true
        }
    },
    {
        path: '/candidacies/:candidacyId/edit',
        name: 'candidacyEdit',
        component: EditCandidacy,
        meta: {
            title: 'Candidature',
            appTitle: 'Candidature',
            requiresAuth: true
        }
    },
    {
        path: '/create/candidacy/:jobId',
        name: 'create-candidacy-id',
        component: CreateCandidacy,
        meta: {
            title: "Création d'une nouvelle candidature",
            appTitle: "Création d'une nouvelle candiature",
        }
    },
]

export default routes