<template>
  <div>
    <card class="pa-4">
      <form v-if="tag">
        <b-form-group label="Nom du tag">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="file-alt"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="tag.title" placeholder="Indiquez le nom du tag"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Couleur tag">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="paint-brush"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="tag.color" placeholder="Indiquez la couleur de votre tag"></b-form-input>
          </b-input-group>
        </b-form-group>
        <chrome-picker :value="{hex: tag.color}" @input="updateColor"></chrome-picker>
        <div class="center">
          <b-btn @click="createTag()" append variant="primary">Créer le tag</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { Chrome } from "vue-color";
export default {
  components: {
    "chrome-picker": Chrome
  },
  data() {
    return {
      id: this.$route.params.id,
      tag: {
        title: "",
        color: ""
      }
    };
  },
  computed: {},
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "archive",
      text: "Annuler",
      type: "error",
      click: () => {
        this.$router.push("/tags");
      }
    });
  },
  methods: {
    updateColor(color) {
      this.tag.color = color.hex;
    },
    createTag() {
      this.$store.dispatch("createTag", this.tag).then(res => {
        if (res) {
          this.$router.push("/tags");
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
