<template>
  <div class="default-layout">
    <notifications></notifications>
    <div :class="sidebarStatus ? 'active' : ''" v-touch:swipe.right="openSidebar" id="board-layout">
      <SidebarProfile class="lt-profile" />
      <Sidebar class="lt-sidebar" />
      <Header class="lt-header" />
      <div class="lt-content">
        <Loading v-if="isLoading" />
        <slot></slot>
      </div>
    </div>
  </div>

</template>

<script>
import Header from "@/components/Board/Header";
import Footer from "@/components/Board/Footer";
import SidebarProfile from "@/components/Board/SidebarProfile";
import Sidebar from "@/components/Board/Sidebar";
export default {
  components: {
    Header,
    Footer,
    Sidebar,
    SidebarProfile
  },
  computed: {
    sidebarStatus() {
      return this.$store.getters.sidebarStatus;
    },
    isLoading() {
      return this.$store.getters.isLoading;
    }
  },
  methods: {
    openSidebar() {
      this.$store.commit("OPEN_SIDEBAR");
    }
  }
};
</script>

<style lang="scss">
#board-layout {
  height: 100vh;
  min-height: 100vh;
  display: grid;
  overflow: auto;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: 0.2fr 1.8fr;
  grid-template-areas:
    "profile profile header header header header header header header header header header header header header header"
    "sidebar sidebar content content content content content content content content content content content content content content";
  &.active {
    grid-template-areas:
      "profile header header header header header header header header header header header header header header header"
      "sidebar content content content content content content content content content content content content content content content";
  }
  .lt-profile {
    grid-area: profile;
  }
  .lt-sidebar {
    grid-area: sidebar;
  }
  .lt-header {
    grid-area: header;
  }

  .lt-content {
    grid-area: content;
    //height:100%;
    overflow: scroll;
    padding: 20px;
    position: relative;
    background: #eeeffa;
    &::-webkit-scrollbar {
      width: 22px;
      margin-right: 10px;
    }
    &::-webkit-scrollbar-track {
      margin: 5px 0;
      box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.2);
      border: solid 6px transparent;
      border-radius: 30px;
    }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 10px 10px $primary;
      border: solid 6px transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      box-shadow: inset 0 0 10px 10px $primary-D;
      border: solid 4px transparent;
    }
  }
}
</style>
