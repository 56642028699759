<template>
  <a class="avatar-link" :href="url">
    <div class="avatar" :style="style()">
    </div>
    <h1 v-if="name" v-html="name"></h1>
  </a>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: null
    },
    img: {
      type: String,
      default: "https://via.placeholder.com/200x200"
    },
    radius: {
      type: String,
      default: "50%"
    },
    size: {
      type: String,
      default: "60px"
    },
    name: {
      type: String,
      default: null
    }
  },
  methods: {
    style() {
      return {
        borderRadius: this.radius,
        backgroundImage: `url(${this.img})`,
        width: this.size,
        height: this.size
      };
    }
  }
};
</script>

<style lang="scss">
.avatar-link {
  display:block;
  margin:auto;
  text-align:center;
  text-decoration: none;
  display:flex;
  align-items: center;
}
.avatar {
  display: inline-block;
  background-position: center;
  background-size: cover;
}
</style>
