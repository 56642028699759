<template>
  <div class="login">
    <div @click="$router.push('/login')" class="form-header">
      Already have an account ? Sign in
    </div>
    <form @submit="signup()">
      <div class="input-w">
        <div class="form-icon">
          <font-awesome-icon icon="at" />
        </div>
        <input placeholder="Adresse email" required type="email" v-model="form.email">
      </div>
      <div class="input-w">
        <div class="form-icon">
          <font-awesome-icon icon="at" />
        </div>
        <input placeholder="Nom d'utilisateur" required type="username" v-model="form.username">
      </div>
      <div class="input-w">
        <div class="form-icon">
          <font-awesome-icon icon="unlock" />
        </div>
        <input placeholder="Mot de passe" required type="password" v-model="form.password">
      </div>
      <div class="input-w">
        <div class="form-icon">
          <font-awesome-icon icon="unlock" />
        </div>
        <input placeholder="Répétez votre mot de passe" required type="password" v-model="form.password2">
      </div>
      <div class="input-w">
        <div class="form-icon">
          <font-awesome-icon icon="user" />
        </div>
        <input placeholder="Nom de votre société" required type="text" v-model="form.organizationName">
      </div>
      <div class="my-4 flex">
        <Btn class="mx-3" backgroundColor="#444444" borderColor="#444444" shadow radius="4px" padding="18px 30px" @click.native="$router.push('/login')">J'ai déjà un compte</Btn>
        <Btn class="mx-3" shadow radius="4px" padding="18px 30px" @click.native="signup()">S'inscrire</Btn>
      </div>
      <div v-html="passwordError" class="error" v-if="passwordError"></div>
      <div v-html="error" class="error" v-if="error"></div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      passwordError: null,
      form: {
        email: "",
        password: "",
        username: "",
        password2: "",
        organizationName: ""
      }
    };
  },
  methods: {
    signup() {
      let { password, email, username, password2 } = this.form;
      if (password == password2 && password != "" && password != null) {
        this.$store
          .dispatch("register", {
            userInput: {
              email: this.form.email,
              password: this.form.password,
              username: this.form.username
            },
            organizationInput: {
              name: this.form.organizationName
            }
          })
          .then(register => {
            if (register.ok) {
              this.$router.push("dashboard");
            } else {
              this.error = register.errors[0].message;
            }
          });
      } else {
        this.passwordError = "Les 2 mots de passes sont différents";
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
</style>
