<template>
  <div :class="active" id="sidebar">
    <div class="sidebar-content">
      <SidebarMenu />
    </div>
  </div>
</template>

<script>
import SidebarMenu from "@/components/Board/SidebarMenu";

export default {
  components: {
    SidebarMenu
  },
  computed: {
    active() {
      return this.$store.getters.sidebarStatus ? "active" : "";
    }
  },
  methods: {
    closeSidebar() {
      this.$store.commit("CLOSE_SIDEBAR");
    }
  }
};
</script>

<style lang="scss">
#sidebar {
}
.sidebar-content {
  position: relative;
  z-index: 9;
  height: 100%;
  background: linear-gradient(to bottom, $secondary 0%, $secondary-D 100%);
  .sidebar-logo {
    display: block;
    padding: $sidebarSpacer 0;
    img {
      width: 50%;
      margin: auto;
      height: auto;
    }
  }
}
</style>
