import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import App from './App.vue'
import router from './router/index.js';
import './registerServiceWorker'
import store from './store'
import VueApollo from 'vue-apollo';
Vue.use(VueApollo);
import { apolloProvider, client } from '@/apollo/init.js';

import "@/plugins/fai";
import "@/plugins/tooltip";
import "@/plugins/touch";
import "@/plugins/tables";
import "@/plugins/vuebar";
import "@/plugins/bootstrap";
import "@/plugins/markdown";
import "@/plugins/googleAutocomplete"
import "@/plugins/moment"

import background from "@/mixins/background";
Vue.mixin(background);

import ServerUrl from './plugins/serverUrl'
Vue.use(ServerUrl)


const requireLayout = require.context(`./layouts`, true, /[\w-]+\.vue$/)
requireLayout.keys().forEach(fileName => {
  const layoutConfig = requireLayout(fileName)

  const layoutName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')) + 'Layout'
  )
  Vue.component(layoutName, layoutConfig.default || layoutConfig)
})

const requireComponent = require.context(`./components/Utils`, true, /[\w-]+\.vue$/)
requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, ''))
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})

Vue.config.productionTip = false;

String.prototype.safeCSSId = function () {
  return encodeURIComponent(
    this.toLowerCase()
  ).replace(/%[0-9A-F]{2}/gi, '');
}

new Vue({
  apolloProvider,
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app');
