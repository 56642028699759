<template>
  <div v-if="tags" id="tags">
    <v-client-table class="etalent-table" :options="options" :data="tags" :columns="columns">
      <div slot="title" slot-scope="props">
        <router-link :to="`/tags/${props.row.id}/edit`" v-html="props.row.title || 'Aucun nom'"></router-link>
      </div>
      <ul class="tools" slot="tools" slot-scope="props">
        <li v-tooltip="'Accéder aux candidatures'">
          <router-link :to="`/tags/candidacies/${props.row.id}`">
            <font-awesome-icon icon="eye" class="icon"></font-awesome-icon>
          </router-link>
        </li>
      </ul>
      <div slot="color" slot-scope="props">
        <span class="color-badge" :style="{ backgroundColor: props.row.color }"
          v-html="props.row.color || 'Aucune couleur'"></span>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      columns: ["title", "color", "tools"],
      options: {
        filterByColumn: true,
        headings: {
          title: "Nom du tag",
          tools: "Outils",
          color: "Couleur"
        }
      }
    };
  },
  computed: {
    tags() {
      return this.$store.getters.tags();
    }
  },
  methods: {
    access(tagId) {
      console.log(tagId)
    }
  },
  mounted() {
    this.$store.dispatch("fetchTags");
    this.$store.commit("SET_CTA", {
      icon: "plus-square",
      text: "Créer un nouveau tag",
      click: () => {
        this.$router.push("/create/tag");
      }
    });
  }
};
</script>

<style lang="scss">
.color-badge {
  border-radius: 15px;
  padding: 7px 15px;
  color: #fff;
  background-color: #000;
}
</style>
