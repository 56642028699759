<template>
    <div id="stats">
        En cours d'implémentation
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
