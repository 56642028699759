<template>
    <div :style="style" class="page-title" :class="align">
        <h1 :style="titleStyle" class="title">
            <i v-if="icon" :class="icon" class="fas"></i>
            <slot></slot>
        </h1>
        <span v-if="subtitle" class="subtitle" v-html="subtitle"></span>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            default: 'Titre par défaut',
            type: String
        },
        align: {
            default: 'center',
            type: String
        },
        padding: {
            default: 70,
            type: Number
        },
        icon: {
            default: null,
            type: String
        },
        subtitle: {
            default: null,
            type: String
        },
        color: {
            default: "#008BDD",
            type: String
        }
    },
    computed: {
        style() {
            return {
                padding: `${this.padding}px 0`
            }
        },
        titleStyle() {
            return {
                color: this.color
            }
        }
    }
}
</script>

<style lang="scss">

.page-title {
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
  h1 {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    justify-content: center;
    i {
      margin-right: 15px;
    }
  }
  span.subtitle {
    display: block;
    margin-top: 7px;
  }
}

@media screen and (max-width: 1000px) {
  .page-title {
    padding: 20px 0 !important;
  }
}
</style>
