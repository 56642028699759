import { client } from "@/apollo/init.js";
import Vue from "vue";
import CANDIDACIES from "@/api/queries/Candidacy/candidacies.graphql";
import CANDIDACY from "@/api/queries/Candidacy/candidacy.graphql";

import SET_CANDIDACY_FOLDER from "@/api/mutations/Candidacy/setCandidacyFolder.graphql";
import DELETE_CANDIDACY from "@/api/mutations/Candidacy/deleteCandidacy.graphql";
import UPDATE_CANDIDACY from "@/api/mutations/Candidacy/updateCandidacy.graphql";

import CREATE_COMMENT from "@/api/mutations/Comment/createComment.graphql";
import DELETE_COMMENT from "@/api/mutations/Comment/deleteComment.graphql";
import UPDATE_COMMENT from "@/api/mutations/Comment/updateComment.graphql";

import { extract } from "@/utils/functions";

const defaultState = {
  candidacies: [],
  candidacy: null,
};

const actions = {
  fetchCandidacies: (context, { offset, jobId, folderId }) => {
    let variables = {
      offset: offset,
      folderId: folderId,
      limit: 2000
    };
    if (jobId) {
      variables.jobId = jobId;
    }
    return client
      .query({
        query: CANDIDACIES,
        variables,
      })
      .then((res) => {
        console.log(res.data.candidacies)
        context.commit("PUSH_CANDIDACIES", res.data.candidacies);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  fetchCandidacy: (context, id) => {
    return client
      .query({
        query: CANDIDACY,
        variables: {
          id,
        },
      })
      .then((res) => {
        context.commit("SET_CANDIDACY", res.data.candidacy);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  deleteCandidacy: (context, id) => {
    return client
      .mutate({
        mutation: DELETE_CANDIDACY,
        variables: { id },
      })
      .then((res) => {
        if (res.data.deleteCandidacy.ok) {
          context.commit("REMOVE_CANDIDACY", id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  setCandidacyFolder: (context, { candidacyId, folderId }) => {
    return client
      .mutate({
        mutation: SET_CANDIDACY_FOLDER,
        variables: {
          candidacyId,
          folderId,
        },
      })
      .then((res) => {
        if (res.data.setCandidacyFolder.ok) {
          context.dispatch("pushNotif", {
            type: "success",
            message: "La candidature a bien été déplacée dans le nouveau dossier",
          });
        } else {
          context.dispatch("pushNotif");
        }
      })
      .catch((err) => {
        console.log(err);
        context.dispatch("pushNotif");
      });
  },
  updateCandidacy: (context, { candidacyInput, id }) => {
    candidacyInput = extract(candidacyInput, [
      "id",
      "folder",
      "job",
      "actions",
      "tags",
      "reviews",
      "comments",
    ]);
    return client
      .mutate({
        mutation: UPDATE_CANDIDACY,
        variables: {
          candidacyInput,
          id,
        },
      })
      .then((res) => {
        if (res.data.updateCandidacy.ok) {
          context.dispatch("pushNotif", { type: "success", message: "Candidature mise à jour" });
        } else {
          context.dispatch("pushNotif");
        }
      })
      .catch((err) => {
        context.dispatch("pushNotif");
      });
  },
  createComment: (context, { commentInput, candidacyId }) => {
    return client
      .mutate({
        mutation: CREATE_COMMENT,
        variables: {
          commentInput,
          candidacyId,
        },
      })
      .then((res) => {
        if (res.data.createComment.ok) {
          context.commit("PUSH_COMMENT", res.data.createComment.comment);
          return true;
        }
        return false;
      })
      .catch((err) => {
        return false;
      });
  },
  deleteComment: (context, id) => {
    return client
      .mutate({
        mutation: DELETE_COMMENT,
        variables: { id },
      })
      .then((res) => {
        if (res.data.deleteComment.ok) {
          context.commit("REMOVE_COMMENT", id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  updateComment: (context, comment) => {
    let { body, id } = comment;
    return client.mutate({
      mutation: UPDATE_COMMENT,
      variables: {
        commentInput: {
          body,
        },
        id,
      },
    });
  },
};

const mutations = {
  PUSH_CANDIDACIES: (state, candidacies) => {
    state.candidacies.push(
      ...candidacies.map((candidacy) => {
        candidacy.created_at_timestamp = new Date(candidacy.created_at).getTime();
        return candidacy;
      })
    );
  },
  RESET_CANDIDACIES: (state, candidacies) => {
    state.candidacies = [];
  },
  SET_CANDIDACY: (state, candidacy) => {
    state.candidacy = candidacy;
  },
  REMOVE_CANDIDACY: (state, id) => {
    state.candidacies = state.candidacies.filter((el) => {
      return el.id !== id;
    });
  },
  PUSH_COMMENT: (state, comment) => {
    if (state.candidacy.comments) {
      state.candidacy.comments.push({ ...comment });
    }
  },
  REMOVE_COMMENT: (state, id) => {
    if (state.candidacy.comments) {
      state.candidacy.comments = state.candidacy.comments.filter((el) => {
        return el.id !== id;
      });
    }
  },
  UPDATE_COMMENT: (state, comment) => {
    let comments = state.candidacy.comments;
    if (comments) {
      let index = Object.keys(comments).find((key) => comments[key].id === comment.id);
      state.candidacy.comments[index] = comment;
      Vue.set(state.candidacy.comments, index, comment);
    }
  },
};

const getters = {
  candidacies: (state) => () => {
    return state.candidacies;
  },
  candidacy: (state) => () => state.candidacy,
};

export default {
  state: defaultState,
  getters,
  actions,
  mutations,
};
