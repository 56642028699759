import Jobs from '@/views/Jobs/Jobs.vue'
import Job from '@/views/Jobs/Job.vue'
import CreateJob from '@/views/Jobs/Create.vue'
import EditJob from '@/views/Jobs/Edit.vue'
import ShareJob from '@/views/Jobs/Share.vue'

const routes = [
    {
        path: '/jobs',
        name: 'jobs',
        component: Jobs,
        meta: {
            title: 'Vos offres d\'emploi',
            appTitle: 'Vos offres d\'emploi',
            requiresAuth: true
        }
    },
    {
        path: '/:closed/jobs',
        name: 'jobs-closed',
        component: Jobs,
        meta: {
            title: 'Vos offres d\'emploi',
            appTitle: 'Vos offres d\'emploi',
            requiresAuth: true
        }
    },
    {
        path: '/jobs/:id',
        name: 'job',
        component: Job,
        meta: {
            title: 'Offre d\'emploi',
            appTitle: 'Offre d\'emploi',
            requiresAuth: true
        }
    },
    {
        path: '/jobs/:id/edit',
        name: 'job-edit',
        component: EditJob,
        meta: {
            title: 'Offre d\'emploi',
            appTitle: 'Offre d\'emploi',
            requiresAuth: true
        }
    },
    {
        path: '/jobs/:id/share',
        name: 'job-share',
        component: ShareJob,
        meta: {
            title: 'Partager une offre',
            appTitle: 'Partager une offre',
            requiresAdmin: true
        }
    },
    {
        path: '/create/job',
        name: 'create-job',
        component: CreateJob,
        meta: {
            title: "Création d'une nouvelle offre d'emploi",
            appTitle: "Création d'une nouvelle offre d'emploi",
            requiresAdmin: true
        }
    },
]

export default routes