<template>
  <div id="search-page">
    <form @submit.prevent="handleSubmit()">
      <div class="input-block">
        <input required type="text" id="text" name="text" v-model="search" placeholder="Mots clés">
        <button type="submit">Rechercher</button>
      </div>
    </form>
    <div v-if="candidacies">
      <b-list-group v-if="candidacies.length > 0">
        {{candidacies.length}} résultat(s)
        <b-list-group-item v-for="candidacy in candidacies" :key="candidacy.id" :to="`/candidacies/${candidacy.id}`" class="flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1" v-html="candidacy.name + ' ' + candidacy.firstname"></h5>
            <small>{{$moment(candidacy.created_at).fromNow()}}</small>
          </div>
          <p v-html="candidacy.mail" class="mb-1"></p>
          <small v-html="candidacy.job.title"></small>
        </b-list-group-item>
      </b-list-group>
      <div v-else>
        Aucune candidature
      </div>
    </div>

  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import CANDIDACIES from "@/api/queries/Candidacy/candidacies.graphql";
export default {
  data() {
    return {
      text: this.$route.params.text,
      search: this.$route.params.text,
      candidacies: null
    };
  },
  methods: {
    processSearch() {
      if (this.search.length > 3) {
        this.fetchCandidacies();
      }
    },
    handleSubmit() {
      if (this.search === this.text && this.search.length > 3) {
        this.fetchCandidacies();
      } else {
        this.$router.replace(`/search/${this.search}`);
      }
    },
    async fetchCandidacies() {
      let res = await client.query({
        query: CANDIDACIES,
        variables: {
          search: this.text,
          limit: 100
        }
      });
      this.candidacies = res.data.candidacies;
    }
  },
  mounted() {
    if (this.text) {
      this.processSearch();
    }
  }
};
</script>

<style lang="scss">
#search-page {
  form {
    .input-block {
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      input {
        flex: 1;
        padding: 10px 15px;
        border: 1px solid $lightgrey;
        font-size: 20px;
      }
      button {
        padding: 10px 15px;
        font-size: 20px;
        border: 1px solid $lightgrey;
        background-color: $primary;
        color: #fff;
        &:hover {
          background-color: $primary-D;
        }
      }
    }
  }
}
</style>