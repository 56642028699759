<template>
  <div id="candidacy-edit">
    <card v-if="candidacy" class="pa-4">
      <form>
        <b-form-group label="Nom du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.name" placeholder="Indiquez le nom du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Prénom du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.firstname" placeholder="Indiquez le prénom du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Adresse email du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.mail" placeholder="Indiquez l'adresse email du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Numéro de téléphone du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.phone" placeholder="Indiquez le numéro de téléphone du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Commentaires du candidat">
          <b-form-textarea rows="6" v-model="candidacy.body" placeholder="Texte..."></b-form-textarea>
        </b-form-group>

        <b-form-group label="CV">
          <upload-file height="100px" :preview="false" v-model="candidacy.resume" />
        </b-form-group>
        <b-form-group label="Lettre de motivation">
          <upload-file height="100px" :preview="false" v-model="candidacy.motivation" />
        </b-form-group>
        <div class="center">
          <b-btn @click="updateCandidacy()" variant="primary">Modifier la candidature</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.candidacyId
    };
  },
  computed: {
    candidacy() {
      return this.$store.getters.candidacy();
    }
  },
  mounted() {
    this.$store.dispatch("fetchCandidacy", this.id);
    this.$store.commit("SET_CTA", {
      icon: "eye",
      text: "Voir la candidature",
      type: "info",
      click: () => {
        this.$router.push(`/candidacies/${this.id}`);
      }
    });
  },
  methods: {
    updateCandidacy() {
      this.$store.dispatch("updateCandidacy", {
        candidacyInput: this.candidacy,
        id: this.candidacy.id
      });
    }
  }
};
</script>

<style lang="scss">
</style>
