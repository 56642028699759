<template>
  <div class="recover-password">
    <form @submit.prevent="recoverPassword()">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="user"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input v-model="form.email" placeholder="Indiquez votre adresse email" required></b-form-input>
      </b-input-group>
      <div class="my-4 flex">
        <Btn v-if="!success" :disabled="checkForm()" class="mx-3" shadow radius="4px" padding="18px 30px" @click.native="recoverPassword()">
          <span>Envoyer un email</span>
        </Btn>
      </div>
      <div v-html="error" class="error" v-if="error"></div>
      <div class="success" v-if="success">Un email a été envoyé à l'adresse {{form.email}} pour récupérer votre mot de passe. </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      error: false,
      success: false,
      form: {
        email: ""
      }
    };
  },
  methods: {
    checkForm() {
      return !(this.form.email.length > 4);
    },
    recoverPassword() {
      this.$store
        .dispatch("recoverPassword", this.form.email)
        .then(ok => {
          this.success = ok;
          this.error = ok
            ? false
            : "Impossible de récupérer le mot de passe pour cette adresse email";
        })
        .catch(e => {
          this.error = "Erreur serveur";
        });
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
</style>
