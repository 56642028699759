<template>
  <div id="job-share">
    <card class="pa-4">
      <ul class="list">
        <li class="item" v-for="user in users" :key="user.id">
          <div>
            <Avatar :img="user.imgUrl" size="40px" />
          </div>
          <p v-html="user.username"></p>
          <font-awesome-icon v-if="hasAccess(user.id)" class="navbar-icon" @click="giveAccess(user.id)" icon="plus-circle" />
          <font-awesome-icon v-if="!hasAccess(user.id)" class="navbar-icon" @click="revokeAccess(user.id)" icon="minus-circle" />
        </li>
      </ul>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import USERS_ACCESS from "@/api/queries/Access/usersAccess.graphql";
import GIVE_ACCESS from "@/api/mutations/Access/giveAccess.graphql";
import REVOKE_ACCESS from "@/api/mutations/Access/revokeAccess.graphql";
export default {
  data() {
    return {
      usersWithAccess: [],
      id: this.$route.params.id
    };
  },
  computed: {
    job() {
      return this.$store.getters.job();
    },
    users() {
      return this.$store.getters.users();
    }
  },
  mounted() {
    this.$store.dispatch("fetchJob", this.id);
    this.$store.dispatch("fetchUsers");
    this.fetchUsersWithAcess();
  },
  methods: {
    hasAccess(userId) {
      return this.usersWithAccess.findIndex((i) => i.id === userId) < 0 
    },
    fetchUsersWithAcess() {
      client
        .query({
          query: USERS_ACCESS,
          variables: {
            jobId: this.id
          }
        })
        .then(res => {
          this.usersWithAccess = res.data.usersAccess;
        });
    },
    async giveAccess(userId) {
      await client.mutate({
        mutation: GIVE_ACCESS,
        variables: {
          jobId: this.id,
          userId
        }
      });
      this.fetchUsersWithAcess();
    },
    async revokeAccess(userId) {
      await client.mutate({
        mutation: REVOKE_ACCESS,
        variables: {
          jobId: this.id,
          userId
        }
      });
      this.fetchUsersWithAcess();
    }
  }
};
</script>

<style lang="scss">
#job-share {
  .list {
    > li {
      display: flex;
      align-items: center;
      p {
        margin: 0 10px;
        font-size: 1.3rem;
      }
      svg {
        color: $primary;
        margin: 0 7px;
        font-size: 1.4rem;
        cursor: pointer;
        &:hover {
          color: $primary-D;
        }
      }
    }
  }
}
</style>
