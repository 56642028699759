import { client } from '@/apollo/init.js'
import Vue from 'vue'
import FOLDERS from "@/api/queries/Folder/folders.graphql"
import CREATE_FOLDER from "@/api/mutations/Folder/createFolder.graphql";
import DELETE_FOLDER from "@/api/mutations/Folder/deleteFolder.graphql";

const defaultState = {
    folders: [],
    folder: null
};

const actions = {
    fetchFolders: (context, payload) => {
        return client.query({
            query: FOLDERS
        }).then(res => {
            context.commit('SET_FOLDERS', res.data.folders)
        }).catch(e => {
            console.log(e)
        })
    },
    createFolder: (context, folderInput) => {
        return client.mutate({
            mutation: CREATE_FOLDER,
            variables: {
                folderInput
            }
        }).then(res => {
            return res.data.createFolder.ok
        }).catch(err => {
            console.log(err)
            return false
        })
    },
    deleteFolder: (context, id) => {
        return client.mutate({
            mutation: DELETE_FOLDER,
            variables: {
                id
            }
        }).then(res => {
            return res.data.deleteFolder.ok
        }).catch(err => {
            console.log(err)
            return false
        })
    }
};

const mutations = {
    SET_FOLDERS: (state, payload) => {
        state.folders = payload
    },
    SET_FOLDER: (state, payload) => {
        state.folder = payload
    },
};

const getters = {
    folders: (state) => () => state.folders,
    folder: (state) => () => state.folder,
    foldersOptions: state => () => {
        if (state.folders) {
            let options = state.folders.map((folder) => {
                return {
                    text: folder.name,
                    value: folder.id
                }
            })
            options.unshift({
                text: "Aucun",
                value: null
            })
            return options
        }
    },
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
