import Vue from 'vue'
import store from "@/store"
import Router from 'vue-router'
Vue.use(Router);

let routes = []

const requireRoute = require.context(`./routes`, true, /[\w-]+\.js$/)
requireRoute.keys().forEach(fileName => {
    const route = requireRoute(fileName)
    routes = routes.concat(route.default);
})
const router = new Router({
    base: process.env.BASE_URL,
    routes,
    mode: "history"
});

// if user is logged in
router.beforeEach((to, from, next) => {
    let routes = ['login', 'signup']
    if (routes.includes(to.name)) {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            next('/')
        }
        else {
            next()
        }
    }
    else {
        next()
    }
})

//if route requires admin Auth
/*router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user && user.role === 'admin') {
      next()
    }
    else if (user) {
      next({ path: '/' })
    }
    else {
      next({ path: '/login' })
    }
  } else {
    next()
  }
})*/

// if route requires Auth
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const user = localStorage.getItem('user')
        if (user) {
            next()
        }
        else {
            next({
                path: '/login',
                query: {
                    to: to.path
                }
            })
        }
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    store.commit('RESET_CTA')
    store.commit('SET_APP_TITLE', to.meta.appTitle)
    store.commit('UPDATE_LOADING', 1)
    document.title = 'eTalent - ' + to.meta.title
    next()
})

router.afterEach((to, from) => {
    store.commit('UPDATE_LOADING', -1)
})


export default router