<template>
  <div class="candidacy-tags">
    <b-dropdown class="ml-2" v-if="jobs" text="Basculer la candidature" variant="primary">
      <b-dropdown-item class="custom-item" @click="toggleCandidacy(job.id)" v-for="job in jobs" :key="job.id">
        {{ job.title }}
      </b-dropdown-item>
    </b-dropdown>
    <ul>
      <li v-for="tag in tags" :key="tag.id" :style="{ backgroundColor: tag.color }">
        <span v-html="tag.title"></span>
        <font-awesome-icon class="icon" @click="removeTag(tag.id)" icon="times"></font-awesome-icon>
      </li>
    </ul>

    <b-dropdown class="ml-2" v-if="tagsEnum" text="Ajouter un tag" variant="primary">
      <b-dropdown-item class="custom-item" @click="assignTag(tag.id)" v-for="tag in tagsEnum" :key="tag.id">
        <span class="color" :style="{ backgroundColor: tag.color }"></span>
        {{ tag.title }}

      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import ASSIGN_TAG from "@/api/mutations/Tag/assignTag.graphql";
import TOGGLE_CANDIDACY from "@/api/mutations/Candidacy/toggleCandidacy.graphql";
import REMOVE_TAG from "@/api/mutations/Tag/removeTag.graphql";

export default {
  props: {
    tags: {
      type: Array,
      default: null
    },
    candidacyId: {
      type: Number,
      default: null
    }
  },
  computed: {
    tagsEnum() {
      return this.$store.getters.tags();
    },
    jobs() {
      return this.$store.getters.jobs();
    }
  },
  methods: {
    removeTag(tagId) {
      client
        .mutate({
          mutation: REMOVE_TAG,
          variables: {
            tagId,
            candidacyId: this.candidacyId
          }
        })
        .then(res => {
          this.$store.dispatch("fetchCandidacy", this.candidacyId);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("pushNotif");
        });
    },
    toggleCandidacy(jobId) {
      client
        .mutate({
          mutation: TOGGLE_CANDIDACY,
          variables: {
            candidacyId: this.candidacyId,
            jobId: jobId,
          }
        })
        .then(res => {
          console.log(res)
          this.$store.dispatch("fetchCandidacy", this.candidacyId);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("pushNotif");
        });
    },
    assignTag(tagId) {
      client
        .mutate({
          mutation: ASSIGN_TAG,
          variables: {
            tagId,
            candidacyId: this.candidacyId
          }
        })
        .then(res => {
          this.$store.dispatch("fetchCandidacy", this.candidacyId);
        })
        .catch(err => {
          console.log(err);
          this.$store.dispatch("pushNotif");
        });
    }
  },
  mounted() {
    if (this.tagsEnum.length == 0) {
      this.$store.dispatch("fetchTags");
    }
    if (this.jobs.length == 0) {
      this.$store.dispatch("fetchJobs", { offset: 0, closed: false });
    }
  }
};
</script>

<style lang="scss">
.candidacy-tags {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  >ul {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;

    >li {
      white-space: nowrap;
      background-color: $primary;
      color: #fff;
      padding: 7px 20px;
      border-radius: 5px;
      margin: 0 5px;
      position: relative;

      .icon {
        position: absolute;
        right: 5px;
        top: 5px;
        font-size: 13px;
        cursor: pointer;
      }
    }
  }
}

.custom-item {
  >a {
    display: flex;
    align-items: center;

    .color {
      width: 22px;
      border-radius: 3px;
      height: 22px;
      display: block;
      margin-right: 7px;
    }
  }
}
</style>
