<template>
  <div v-if="candidacy" id="action-form">
    <b-dropdown id="addAction" text="Ajouter une action" variant="primary">
      <b-dropdown-item @click="createAction(action)" v-for="action in actionsEnum" :key="action.name">{{action.name}}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>


<script>
import { client } from "@/apollo/init.js";
import CREATE_ACTION from "@/api/mutations/Action/createAction.graphql";
export default {
  props: {
    candidacy: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      actionsEnum: [
        {
          name: "Tri du CV",
          state: "En cours"
        },
        {
          name: "Entretien téléphonique",
          state: "En cours"
        },
        {
          name: "Echange téléphonique",
          state: "En cours"
        },
        {
          name: "Entretien physique individuel ou collectif",
          state: "En cours"
        },
        {
          name: "Refus direct",
          state: "Refusée"
        },
        {
          name: "Refus après échanges",
          state: "Refusée"
        },
        {
          name: "Recrutement du candidat",
          state: "Acceptée"
        },
        {
          name: "Lu mais non traitée",
          state: "Créée"
        }
      ]
    };
  },
  methods: {
    createAction(action) {
      client
        .mutate({
          mutation: CREATE_ACTION,
          variables: {
            actionInput: {
              title: action.name
            },
            changeState: action.state,
            candidacyId: this.candidacy.id
          }
        })
        .then(res => {
          this.$store.dispatch("fetchCandidacy", this.candidacy.id);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="scss">
</style>
