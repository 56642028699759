import Societies from '@/views/Societies/Societies.vue'
import Society from '@/views/Societies/Society.vue'
import Department from '@/views/Departments/Department.vue'
import DepartmentEdit from '@/views/Departments/Edit.vue'
import DepartmentCreate from '@/views/Departments/Create.vue'

import SocietyEdit from '@/views/Societies/Edit.vue'
import SocietyCreate from '@/views/Societies/Create.vue';

const routes = [
    {
        path: '/societies',
        name: 'societies',
        component: Societies,
        meta: {
            title: 'Vos sociétés',
            appTitle: 'Vos sociétés',
        }
    },
    {
        path: '/societies/:id',
        name: 'society',
        component: Society,
        meta: {
            title: 'Société',
            appTitle: 'Société',
        }
    },
    {
        path: '/societies/:id/edit',
        name: 'societyEdit',
        component: SocietyEdit,
        meta: {
            title: 'Modifier une société',
            appTitle: 'Modifier une société',
        }
    },
    {
        path: '/societies/:id/department/:departmentId',
        name: 'department',
        component: Department,
        meta: {
            title: 'Département',
            appTitle: 'Département',
        }
    },
    {
        path: '/societies/:id/department/:departmentId/edit',
        name: 'departmentEdit',
        component: DepartmentEdit,
        meta: {
            title: "Modification d'un département",
            appTitle: "Modification d'un département",
        }
    },
    {
        path: '/create/department/:id',
        name: 'departmentCreate',
        component: DepartmentCreate,
        meta: {
            title: "Création d'un nouveau département",
            appTitle: "Création d'un nouveau département",
        }
    },
    {
        path: '/create/society',
        name: 'societyCreate',
        component: SocietyCreate,
        meta: {
            title: "Création d'une nouvelle société",
            appTitle: "Création d'une nouvelle société",
        }
    }
]

export default routes