<template>
  <div :style="uploadStyle()" class="upload-file">
    <input style="display:none;" :name="id" :id="id" type="file" @change="onInputFile">

    <div class="error" v-if="error">
      Erreur lors de l'upload du fichier
    </div>

    <div @dragleave="onDragleave" @dragover.prevent="onDragover" @drop.prevent.stop="onDropFile" class="placeholder">
      <div class="placeholder-w" v-if="loading">
        <font-awesome-icon icon="spinner" spin></font-awesome-icon>
      </div>
      <div class="placeholder-w" v-else>
        <div v-if="img">
          <div v-if="preview" class="preview" :style="getBg(img)">
            <div class="remove-img">
              <button @click="removeFile()">Supprimer</button>
            </div>
          </div>
          <div v-else>
            <p>{{fileName}} correctement chargé</p>
            <button class="remove-file" @click="removeFile()">
              <font-awesome-icon icon="times"></font-awesome-icon>
            </button>
          </div>
        </div>
        <label v-else :for="id">
          <font-awesome-icon class="upload-icon" icon="cloud-upload-alt"></font-awesome-icon>
          <p v-html="text"></p>
        </label>
      </div>
    </div>

  </div>
</template>

<script>
import { storage } from "@/plugins/firebase.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    text: {
      type: String,
      default: "Charger un fichier"
    },
    width: {
      type: String,
      default: "300px"
    },
    height: {
      type: String,
      default: "300px"
    },
    preview: {
      type: Boolean,
      default: true
    },
    value: null
  },
  data() {
    return {
      id: null,
      img: this.value,
      error: false,
      loading: false,
      fileName: null
    };
  },
  watch: {
    value: function(val) {
      this.img = val;
    }
  },
  methods: {
    uploadStyle() {
      return {
        width: this.width,
        height: this.height
      };
    },
    getBg(url) {
      return {
        backgroundImage: `url(${url})`
      };
    },
    removeFile() {
      this.$emit("input", null);
    },
    onDragover(e) {
      e.target.classList.add("drop-active");
    },
    onDragleave(e) {
      e.target.classList.remove("drop-active");
    },
    onDropFile(e) {
      this.onDragleave(e);
      var files = e.dataTransfer.files;
      let file = files[0];
      this.processFile(file);
    },
    onInputFile(e) {
      let file = e.target.files[0];
      this.processFile(file);
    },
    async processFile(file) {
      this.loading = true;
      const ts = Date.now();
      var sanitizedFilename = file.name
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
      let fileName = ts + "_" + sanitizedFilename;
      try {
        const fileData = await storage.ref(`${fileName}`).put(file);
        const url = await fileData.ref.getDownloadURL();
        if (url) {
          this.loading = false;
          this.error = false;
          this.$emit("input", url);
          this.img = url;
          this.fileName = file.name;
        }
      } catch (err) {
        console.log(err);
        this.error = true;
        this.loading = false;
      }
    }
  },
  mounted() {
    this.id =
      "_" +
      Math.random()
        .toString(36)
        .substr(2, 9);
  }
};
</script>

<style lang="scss">
.upload-file {
  max-width: 90%;
}
.placeholder {
  width: 100%;
  height: 100%;
  background-color: #edefed;
  padding: 9px;
  border-radius: 3px;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  position: relative;
  .placeholder-w {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #edefed;
    transition: background-color 0.4s;
    justify-content: center;
    position: relative;
    &.drop-active {
      background-color: rgba(0, 0, 0, 0.4);
      p {
        color: #fff;
      }
    }
    label {
      display: block;
      cursor: pointer;
      .upload-icon {
        display: block;
        margin: 10px auto;
        font-size: 2rem;
        color: $primary;
        transform: scale(1);
        transition: transform 0.3s;
      }
      &:hover {
        .upload-icon {
          transform: scale(1.1);
        }
      }
    }
  }
  .remove-file {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 5px;
    right: 3px;
    cursor: pointer;
    color: $red;
  }
  .preview {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    .remove-img {
      transition: background-color 0.4s;
      background-color: rgba(0, 0, 0, 0);
      position: relative;
      z-index: -2;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        background-color: transparent;
        border: 2px solid #fff;
        color: #fff;
        border-radius: 3px;
        padding: 8px 20px;
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.5);
        }
      }
    }
    &:hover {
      .remove-img {
        z-index: 2;
        visibility: visible;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  p {
    color: #000;
    text-align: center;
    margin:0;
  }
  img {
    width: 100%;
    height: auto;
  }
}
</style>
