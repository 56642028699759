<template>
  <div id="app">
    <component :is="layout">
      <router-view :key="$route.fullPath"/>
    </component>
  </div>
</template>

<script>
import gmapsInit from "@/utils/gmap";
const default_layout = "default";
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },
  async mounted() {
    try {
      const google = await gmapsInit();
    } catch (error) {
      console.error(error);
    }
  },
  created() {},
  beforeCreate() {}
};
</script>


<style lang="scss">
@import "@/css/main.scss";
#app {
  background-color: #edefed;
}
</style>
