<template>
  <div v-if="job" id="job">
    <card class="pa-4">
      <h2 class="center py-3" v-html="job.header"></h2>
      <div :class="job.closed ? 'error' : 'success'" class="job-status">{{job.closed ? 'Clotûrée' : 'Publique'}}</div>
      <div class="job-title">Poste : {{job.title}}</div>
      <vue-markdown class="markdown">{{job.body}}</vue-markdown>

      <div class="job-title">Missions</div>
      <vue-markdown class="markdown">{{job.missions}}</vue-markdown>

      <div class="job-title">Profil</div>
      <vue-markdown class="markdown">{{job.profil}}</vue-markdown>

      <div class="job-title">Informations</div>
      <ul class="info-list">
        <li v-if="job.place">
          <font-awesome-icon class="icon" icon="map-marker-alt"></font-awesome-icon>
          <span v-html="job.place"></span>
        </li>
        <li v-if="job.startAt">
          <font-awesome-icon class="icon" icon="calendar"></font-awesome-icon>
          <span v-html="job.startAt"></span>
        </li>
        <li v-if="job.type">
          <font-awesome-icon class="icon" icon="file"></font-awesome-icon>
          <span v-html="job.type"></span>
        </li>
        <li v-if="job.availability">
          <font-awesome-icon class="icon" icon="file"></font-awesome-icon>
          <span v-html="job.availability"></span>
        </li>
        <li v-if="job.salary">
          <font-awesome-icon class="icon" icon="dollar-sign"></font-awesome-icon>
          <span v-html="job.salary"></span>
        </li>
      </ul>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id
    };
  },
  computed: {
    job() {
      return this.$store.getters.job();
    }
  },
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "pencil-alt",
      text: "Editer l'offre",
      click: () => {
        this.$router.push(`/jobs/${this.id}/edit`);
      }
    });
    this.$store.dispatch("fetchJob", this.id);
  }
};
</script>

<style lang="scss">
#job {
  .markdown {
    padding:0 5px;
  }
  .job-status {
    position:absolute;
    right:0;
    top:0;
    padding:10px 20px;
    font-weight:bold;
    text-transform:uppercase;
    z-index:1;
    color:#fff;
    border-bottom-left-radius: 3px;
    &.success {
      background-color:$success;
    }
    &.error {
      background-color:$error;
    }
  }
  .job-title {
    background-color:$primary;
    color:#fff;
    text-align: center;
    padding:15px 0;
    font-size:22px;
    margin:5px 0;
  }
  .info-list {
    display:flex;
    align-items: center;
    justify-content: center;
    margin:25px 0;
    > li {
      flex:1;
      width:100%;
      display:flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      
      .icon {
        color:$primary;
        font-size:45px;
        margin-bottom:15px;
      }
      span {
        font-weight:bold;
        text-transform: uppercase;
        color:$text;
      }
    }
  }
}
</style>
