import Tags from '@/views/Tags/Tags.vue'
import CreateTag from '@/views/Tags/Create.vue';
import EditTag from '@/views/Tags/Edit.vue';
import TagCandidacies from "@/views/Tags/Candidacies.vue"

const routes = [
    {
        path: '/tags',
        name: 'Liste des tags',
        component: Tags,
        meta: {
            title: "Liste des tags",
            appTitle: "Liste des tags"
        }
    },

    {
        path: '/create/tag',
        name: "Création d'un tag",
        component: CreateTag,
        meta: {
            title: "Création d'un nouveau tag",
            appTitle: "Création d'un nouveau tag",
        }
    },
    {
        path: '/tags/:id/edit',
        name: "Modification d'un tag",
        component: EditTag,
        meta: {
            title: "Modification d'un tag",
            appTitle: "Modification d'un tag",
        }
    },
    {
        path: '/tags/candidacies/:id',
        name: "Liste des candidatures",
        component: TagCandidacies,
        meta: {
            title: "Liste des candidatures",
            appTitle: "Liste des candidatures",
        }
    },
]

export default routes