import { client } from '@/apollo/init.js';

import USERS from "@/api/queries/User/users.graphql";
import CREATE_USER from "@/api/mutations/User/createUser.graphql"

import router from "../../router/index"

const defaultState = {
    users: null
};

const actions = {
    fetchUsers: (context) => {
        return client.query({
            query: USERS
        }).then(res => {
            context.commit('SET_USERS', res.data.users)
        })
    },
    createUser: (context, userInput) => {
        return client.mutate({
            mutation: CREATE_USER,
            variables: {
                userInput
            }
        }).then(res => {
            context.dispatch("pushNotif", {
                type: "success",
                message: "Utilisateur crée avec succès"
            });
            router.push("/users");
            return true
        }).catch(err => {
            context.dispatch('pushNotif')
            return false
        })
    }
};

const mutations = {
    SET_USERS: (state, users) => {
        state.users = users
    }
};

const getters = {
    users: state => () => state.users
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
