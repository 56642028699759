<template>
  <div v-if="comment" class="comment">
    <div v-if="comment.user" class="avatar-w">
      <div :style="setBg(comment.user.imgUrl)" class="avatar"></div>
    </div>
    <textarea :readonly="readOnly()" :id="`comment-${comment.id}`" v-model="comment.body"></textarea>
    <div class="comment-date">
      {{comment.created_at | moment("from", "now")}}
    </div>
    <div v-if="comment.user && comment.user.id === user.id" class="tools">
      <button class="info" v-if="!editing" @click="edit(comment.id)">
        <font-awesome-icon class="icon" icon="pencil-alt"></font-awesome-icon>
      </button>
      <button class="warning" v-if="editing" @click="cancelEditing()">
        <font-awesome-icon class="icon" icon="times"></font-awesome-icon>
      </button>
      <button class="success" v-if="editing" :disabled="comment.body === old.body" @click="updateComment(comment)">
        <font-awesome-icon class="icon" icon="check"></font-awesome-icon>
      </button>
      <button class="error" @click="deleteComment(comment.id)">
        <font-awesome-icon class="icon" icon="trash-alt"></font-awesome-icon>
      </button>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    comment: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      editing: false,
      old: null
    };
  },
  computed: {
    user() {
      return this.$store.getters.user();
    }
  },
  methods: {
    deleteComment(id) {
      this.$store.dispatch("deleteComment", id);
    },
    readOnly() {
      return this.editing ? false : "readonly";
    },
    edit(id) {
      document.getElementById(`comment-${id}`).focus();
      this.editing = true;
      this.old = Object.assign({}, this.comment);
    },
    cancelEditing() {
      this.editing = false;
      this.$store.commit("UPDATE_COMMENT", this.old);
    },
    updateComment(comment) {
      this.$store.dispatch("updateComment", comment).then(() => {
        this.editing = false;
      });
    }
  }
};
</script>

<style lang="scss">
.comment {
  display: flex;
  .comment-date {
    display:flex;
    align-items: center;
    justify-content: center;
    font-size:15px;
    color:$text;
    margin-right:10px;
  }
  .avatar-w {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    .avatar {
      width: 40px;
      height: 40px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  textarea {
    flex: 1;
    border: 1px solid transparent;
    padding: 5px 10px;
    background-color: transparent;
    height:auto;
  }
  .tools {
    display: none;
    button {
      padding: 0 15px;
      border: none;
      height: 100%;
      color: #fff;
      &:disabled {
        display: none;
      }
      &.success {
        background: $success;
        &:hover {
          background: $success-D;
        }
      }
      &.info {
        background: $info;
        &:hover {
          background: $info-D;
        }
      }
      &.warning {
        background: $warning;
        &:hover {
          background: $warning-D;
        }
      }
      &.error {
        background: $error;
        &:hover {
          background: $error-D;
        }
      }
    }
  }
  &:hover {
    .tools {
      display: block;
    }
  }
}
</style>
