<template>
  <div v-if="candidacy" id="candidacy-component">
    <CandidacyNav :previous="candidacy.previous" :next="candidacy.next" />
    <div id="candidacy">
      <div>
        <Infos :candidacy="candidacy" />
        <CandidacyCard :candidacy="candidacy" />
        <Comments :candidacyId="candidacyId" :comments="candidacy.comments" />
        <!--<Reviews :id="candidacy.id" :reviews="candidacy.reviews" />-->
        <History :candidacy="candidacy" />
        <Others :candidacy="candidacy" />
      </div>
      <div>
        
        <Tags :candidacyId="candidacy.id" :tags="candidacy.tags" />
        <Tools :candidacy="candidacy" />
        <Viewer :key="candidacy.id" :resume="candidacy.resume" :motivation="candidacy.motivation" />
      </div>
    </div>

  </div>
</template>

<script>
import Comments from "@/components/Comment/Comments";
import CandidacyCard from "@/components/Candidacy/CandidacyCard";
import History from "@/components/Candidacy/History";
import Viewer from "@/components/Candidacy/Viewer";
import Tools from "@/components/Candidacy/Tools";
import Infos from "@/components/Candidacy/Infos";
import Others from "@/components/Candidacy/Others";
import Tags from "@/components/Candidacy/Tags";
import Reviews from "@/components/Candidacy/Reviews";
import CandidacyNav from "@/components/Candidacy/Nav";

export default {
  components: {
    Comments,
    CandidacyCard,
    CandidacyNav,
    History,
    Tools,
    Viewer,
    Infos,
    Tags,
    Others,
    Reviews
  },
  data() {
    return {
      candidacyId: this.$route.params.candidacyId
    };
  },
  computed: {
    candidacy() {
      return this.$store.getters.candidacy();
    }
  },
  mounted() {
    this.$store
      .dispatch("fetchCandidacy", this.candidacyId)
      .then(res => {
        this.$store.commit(
          "SET_APP_TITLE",
          `Candidature de "${this.candidacy.name} ${this.candidacy.firstname}" pour l'offre "${this.candidacy.job.title}"`
        );
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>

<style lang="scss">
#candidacy {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: $gridGap;
  padding: $gridGap;
  height: 150vh;
  width: 100%;
  .col-1 {
    grid-column: 1;
  }
  > div {
    > * {
      margin-bottom: $gridGap;
      /*background-color:#fff;
      border:1px solid darken($bg, 3%);
      box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.22);
      border-radius:2px;*/
    }
  }
}
</style>
