const extract = (obj, keys) => {
    let res = {}
    Object.assign(res, obj);

    let base = ['__typename', 'created_at', 'updated_at']

    base.concat(keys).forEach(key => {
        delete res[key]
    });
    return res
}


export {
    extract
}