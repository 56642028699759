<template>
  <div id="create-user">
    <card class="pa-4">
      <form @submit.prevent="createUser(user)">

        <b-form-group label="Nom d'utilisateur">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="address-book"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.username" placeholder="Indiquez votre nom d'utilisateur"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Rôle">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="sitemap"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-select v-model="user.role" :options="roles"></b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Prénom">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="user"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.firstname" placeholder="Indiquez votre prénom"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Nom de famille">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="user"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.name" placeholder="Indiquez votre nom de famille"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Adresse email">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="envelope"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.email" placeholder="Indiquez votre adresse email"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group v-if="user.role === 'manager' || user.role === 'director'" label="Société">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-select v-model="user.societyId" :options="societiesOptions"></b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group v-if="user.role === 'manager' || user.role === 'director'" label="Département">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-select v-model="user.departmentId" :options="departmentsOptions"></b-form-select>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Votre photo de profil">
          <upload-file v-model="user.imgUrl" />
        </b-form-group>

        <div class="center">
          <b-btn @click="createUser()" append variant="primary">Ajouter le nouvel utilisateur</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      roles: [
        {
          text: "Administrateur",
          value: "admin"
        },
        {
          text: "Directeur de société",
          value: "director"
        },
        {
          text: "Manager de département",
          value: "manager"
        },
        {
          text: "Utilisateur classique",
          value: "user"
        }
      ],
      user: {
        username: "",
        email: "",
        firstname: "",
        name: "",
        role: "user"
      }
    };
  },
  watch: {
    societyId(oldVal, newVal) {
      if (oldVal && newVal) {
        this.departmentId = null;
      }
    }
  },
  computed: {
    societiesOptions() {
      return this.$store.getters.societiesOptions();
    },
    departmentsOptions() {
      return this.$store.getters.departmentsOptions(this.user.societyId);
    }
  },
  mounted() {
    this.$store.dispatch("fetchSocieties");
  },
  methods: {
    createUser() {
      this.$store.dispatch("createUser", this.user);
    }
  }
};
</script>

<style>
</style>
