<template>
  <div v-if="candidacies && folders" class="candidacies-list">
    <v-client-table
      class="etalent-table"
      :options="options"
      name="candidaciesTable"
      :data="candidacies"
      :columns="columns"
    >
      <div slot="check" slot-scope="props">
        <label class="checkbox-container">
          <input
            :checked="isChecked(props.row.id)"
            @input="checkboxChanged($event, props.row.id)"
            type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div slot="name" slot-scope="props">
        <router-link
          :to="`/candidacies/${props.row.id}`"
          v-html="`${props.row.firstname} ${props.row.name}`"
        ></router-link>
      </div>
      <div slot="favorite" slot-scope="props">
        <font-awesome-icon
          class="heart-pointer"
          @click="updateFavorite(props.row, props.row.id)"
          :icon="[props.row.favorite ? 'fas' : 'far', 'heart']"
        ></font-awesome-icon>
      </div>
      <div slot="job" slot-scope="props">
        <router-link :to="`/jobs/${props.row.job.id}`" v-html="props.row.job.title"></router-link>
      </div>
      <div slot="mail" slot-scope="props">
        <a :href="`mailto:${props.row.mail}`" v-html="props.row.mail"></a>
      </div>
      <div slot="phone" slot-scope="props">
        <a :href="`tel:${props.row.phone}`" v-html="props.row.phone"></a>
      </div>

      <div slot="created_at_timestamp" slot-scope="props">
        {{ props.row.created_at_timestamp | moment("DD/MM/YYYY") }}
      </div>

      <div slot="state" slot-scope="props">
        <div
          class="custom-badge badge"
          v-html="props.row.state"
          :class="props.row.state.safeCSSId()"
        ></div>
      </div>

      <div slot="actions" slot-scope="props">
        {{
          props.row.actions && props.row.actions.length > 0
            ? `${props.row.actions[0].title}`
            : "Aucune"
        }}
      </div>

      <div slot="folder" slot-scope="props">
        <b-form-select
          v-if="props.row.folder"
          @change="setCandidacyFolder($event, props.row.id)"
          :value="props.row.folder.id"
          :options="foldersOptions"
        ></b-form-select>
        <b-form-select
          v-else
          @change="setCandidacyFolder($event, props.row.id)"
          :value="null"
          :options="foldersOptions"
        ></b-form-select>
      </div>
      <ul class="tools" slot="tools" slot-scope="props">
        <li v-tooltip="'Télécharger le CV'">
          <a target="_blank" :href="props.row.resume">
            <font-awesome-icon icon="download"></font-awesome-icon>
          </a>
        </li>
        <li v-tooltip="'Télécharger la lettre de motivation'">
          <a target="_blank" :href="props.row.motivation">
            <font-awesome-icon icon="fire"></font-awesome-icon>
          </a>
        </li>
        <li v-tooltip="'Voir la candidature'" @click="show(props.row.id, props.row.job.id)">
          <font-awesome-icon icon="eye" class="icon"></font-awesome-icon>
        </li>
        <li v-if="isAdmin" v-tooltip="'Editer la candidature'" @click="edit(props.row.id)">
          <font-awesome-icon icon="edit" class="icon"></font-awesome-icon>
        </li>
        <li
          v-if="isAdmin"
          v-tooltip="'Supprimer la candidature'"
          @click="deleteCandidacy(props.row.id)"
        >
          <font-awesome-icon icon="trash" class="icon"></font-awesome-icon>
        </li>
      </ul>
    </v-client-table>
  </div>
</template>

<script>
import Vue from "vue";
import FolderModal from "@/components/Folder/FolderModal";

export default {
  components: {
    FolderModal,
  },
  data() {
    return {
      selectedCandidacies: {},
      id: this.$route.params.id,
      columns: [
        "check",
        "state",
        "favorite",
        "name",
        "mail",
        "phone",
        "job",
        "actions",
        "created_at_timestamp",
        "tools",
      ],
      options: {
        orderBy: {
          ascending: false,
          column: "created_at_timestamp",
        },
        sortIcon: {
          base: "fa",
          up: "fa-chevron-up",
          down: "fa-chevron-down",
          is: "fa-sort",
        },
        filterByColumn: true,
        columnsDropdown: true,
        filterable: ["name", "phone", "mail", "job", "folder", "state", "favorite"],
        listColumns: {
          favorite: [
            {
              text: "Oui",
              id: true,
            },
            {
              text: "Non",
              id: false,
            },
          ],
          state: [
            {
              text: "Créée",
              id: "Créée",
            },
            {
              text: "En cours",
              id: "En cours",
            },
            {
              text: "Refusée",
              id: "Refusée",
            },
            {
              text: "Acceptée",
              id: "Acceptée",
            },
          ],
        },
        headings: {
          check: (h) => (
            <label class="checkbox-container">
              <input onInput={this.checkAll} type="checkbox" />
              <span class="checkmark" />
            </label>
          ),
          state: "Etat",
          favorite: "Favoris ?",
          name: "Nom",
          phone: "N° de tel",
          created_at_timestamp: "Créee le",
          job: "Offre concernée",
          tools: "Outils",
          folder: "Dossier",
          actions: "Dernière action",
        },
      },
      candidacyToDelete: {},
    };
  },
  props: {
    candidacies: {
      type: Array,
      default: null,
    },
  },
  computed: {
    folders() {
      return this.$store.getters.folders();
    },
    foldersOptions() {
      return this.$store.getters.foldersOptions();
    },
    isAdmin() {
      return this.$store.getters.isAdmin();
    },
    user() {
      return this.$store.getters.user();
    },
  },
  mounted() {
    this.$store.dispatch("fetchFolders");
  },
  methods: {
    updateFavorite(candidacy) {
      candidacy.favorite = !candidacy.favorite;
      const candidacyInput = { ...candidacy };
      delete candidacyInput.created_at_timestamp;
      this.$store.dispatch("updateCandidacy", {
        candidacyInput,
        id: candidacy.id,
      });
      this.$emit("refetchCandidacies");
    },
    setCandidacyFolder(folderId, candidacyId) {
      this.$store.dispatch("setCandidacyFolder", { folderId, candidacyId });
    },
    checkboxChanged(event, id) {
      if (event.target.checked) {
        Vue.set(this.selectedCandidacies, id, true);
      } else {
        Vue.delete(this.selectedCandidacies, id);
      }
    },
    isChecked(id) {
      return Object.keys(this.selectedCandidacies).includes(id.toString());
    },
    checkAll(event) {
      this.candidacies.map((item) => {
        if (event.target.checked) {
          Vue.set(this.selectedCandidacies, item.id, true);
        } else {
          Vue.delete(this.selectedCandidacies, item.id);
        }
      });
    },
    show(id) {
      this.$router.push(`/candidacies/${id}`);
    },
    edit(id) {
      this.$router.push(`/candidacies/${id}/edit`);
    },
    deleteCandidacy(id) {
      this.$store.dispatch("deleteCandidacy", id);
    },
  },
};
</script>

<style lang="scss">
.heart-pointer {
  cursor: pointer;
}
</style>
