<template>
  <div :style="cardStyle()" class="custom-card">
    <div v-if="$slots.header" class="custom-card-header">
      <slot name="header"></slot>
    </div>
    <div class="custom-card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default: "#fff"
    },
    minHeight: {
      type: String,
      default: "inherit"
    }
  },
  methods: {
    cardStyle() {
      return {
        backgroundColor: this.bgColor,
        minHeight: this.minHeight
      };
    }
  }
};
</script>

<style lang="scss">
.custom-card {
  border-radius: 8px;
  box-shadow: $box-shadow;
  position:relative;
  .custom-card-header {
    display:flex;
    align-items: center;
    justify-content: center;
    padding:22px 20px;
    border-bottom:1px solid #ddd;
    h2 {
      color:$secondary;
      //font-weight:bold;
      font-size:22px;
      flex:1;
      padding-left:15px;
      margin:0;
    }
    .icon {
      color:$primary;
      font-size:27px;      
    }
    .cta {
      display:flex;
      align-items: center;
    }
  }
}
</style>
