<template>
  <div id="create-department">
    <card class="pa-4">
      <form>
        <b-form-group label="Nom du département">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="department.name" placeholder="Indiquez le nom du département"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Logo du département">
          <upload-file v-model="department.photo" />
        </b-form-group>
        <div class="center">
          <b-btn @click="createDepartment()" append variant="primary">Créer le département</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      societyId: this.$route.params.id,
      department: {}
    };
  },
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "archive",
      text: "Annuler la création",
      type: "error",
      click: () => {
        this.$router.push(`/societies/${this.societyId}`);
      }
    });
  },
  methods: {
    createDepartment() {
      this.$store
        .dispatch("createDepartment", {
          societyId: this.societyId,
          departmentInput: this.department
        })
        .then(res => {
          if (res) {
            this.$router.push(`/societies/${this.societyId}`);
            this.$store.dispatch("pushNotif", {
              type: "success",
              message: "Le département a bien été crée"
            });
          } else {
            this.$store.dispatch("pushNotif");
          }
        })
        .catch(err => {
          this.$store.dispatch("pushNotif");
        });
    }
  }
};
</script>

<style>
</style>
