<template>
  <div id="candidacies">
    <CandidaciesList :candidacies="candidacies" @refetchCandidacies="refetch" />
  </div>
</template>

<script>
import Vue from "vue";
import CandidaciesList from "@/components/Candidacy/CandidaciesList";
export default {
  components: {
    CandidaciesList
  },
  data() {
    return {
      id: this.$route.params.id
    };
  },
  computed: {
    candidacies() {
      return this.$store.getters.candidacies();
    }
  },
  methods: {
    fetchCandidacies(offset, jobId) {
      this.$store.dispatch("fetchCandidacies", { offset, jobId: this.id });
    },
    refetch() {
      this.$store.commit("RESET_CANDIDACIES");
      this.$store.dispatch("fetchCandidacies", { offset:0, jobId: this.id });
    }
  },
  mounted() {
    this.fetchCandidacies(0, this.id);
    this.$store.commit("SET_CTA", {
      icon: "pencil-alt",
      text: "Partager l'offre",
      click: () => {
        this.$router.push(`/jobs/${this.id}/share`);
      }
    });
  },
  destroyed() {
    this.$store.commit("RESET_CANDIDACIES");
  }
};
</script>

<style lang="scss">
</style>