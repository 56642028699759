import Dashboard from '@/views/Dashboard.vue'
import Stats from '@/views/Stats.vue'
import TestAPI from "@/views/TestAPI.vue"
import Search from "@/views/Search.vue"

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: `Dashboard`,
            appTitle: 'Dashboard',
            requiresAuth: true
        }
    },
    {
        path: '/stats',
        name: 'stats',
        component: Stats,
        meta: {
            title: `Statisques`,
            appTitle: 'Statistiques',
            requiresAuth: true
        }
    },
    {
        path: '/test',
        name: "test api",
        component: TestAPI,
        meta: {
            title: 'test api',
            appTitle: 'test api'
        }
    },
    {
        path: "/search",
        name: "search page",
        component: Search,
        meta: {
            title: "Recherche",
            appTitle: "Recherche"
        }
    },
    {
        path: "/search/:text",
        name: "search page",
        component: Search,
        meta: {
            title: "Recherche",
            appTitle: "Recherche"
        }
    }
]

export default routes