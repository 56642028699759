<template>
  <div id="profile">
    <card class="pa-4">
      <form @submit.prevent="updateUser(user)">

        <b-form-group label="Nom d'utilisateur">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="address-book"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.username" placeholder="Indiquez votre nom d'utilisateur"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Prénom">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="user"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.firstname" placeholder="Indiquez votre prénom"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Nom de famille">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="user"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.name" placeholder="Indiquez votre nom de famille"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Adresse email">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="envelope"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="user.email" placeholder="Indiquez votre adresse email"></b-form-input>
          </b-input-group>
        </b-form-group>

         <b-form-group label="Nouveau mot de passe (laisser vide sinon)">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="user"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input type="password" v-model="newPassword" placeholder="Indiquez votre nouveau mot de passe"></b-form-input>
          </b-input-group>
        </b-form-group>

         <b-form-group label="Confirmation du nouveau mot de passe">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="user"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input type="password" v-model="confirmPassword" placeholder="Confirmez votre nouveau mot de passe"></b-form-input>
          </b-input-group>
        </b-form-group>

        <b-form-group label="Votre photo de profil">
          <upload-file v-model="user.imgUrl" />
        </b-form-group>
        <div class="center">
          <b-btn :disabled="isValid()" @click="updateUser(user)" append variant="primary">Enregistrer</b-btn>
        </div>
      </form>
    </card>

  </div>
</template>

<script>
export default {
  data() {
    return {
      newPassword: null,
      confirmPassword: null
    };
  },
  computed: {
    user() {
      return this.$store.getters.user();
    },
    societies() {
      return this.$store.getters.societies();
    }
  },
  mounted() {
    this.$store.dispatch("fetchSocieties");
  },
  methods: {
    updateUser(user) {
      if(this.newPassword === this.confirmPassword && this.newPassword != null) {
        user.password = this.newPassword
      }
      this.$store.dispatch("updateUser", user);
    },
    isValid() {
      return !(this.newPassword === this.confirmPassword)
    },
    /*updateUserImg(event) {
      let file = event.target.files[0];
      if (file) {
        this.$store.dispatch("uploadFile", { file }).then(res => {
          if (res) {
            this.user.imgUrl = res;
          }
        });
      }
    }*/
  }
};
</script>

<style lang="scss">
#profile {
}
</style>
