import Login from '@/views/Login.vue'
import Logout from '@/views/Logout.vue'
import Signup from '@/views/Signup.vue'
import RecoverPassword from '@/views/RecoverPassword.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import Users from '@/views/Users/Users.vue'
import CreateUser from '@/views/Users/Create.vue'
import Organization from '@/views/Organization.vue'

import Profile from '@/views/Profile.vue'

const routes = [
    {
        path: '/organization',
        name: 'organization',
        component: Organization,
        meta: {
            title: 'Votre organisation',
            appTitle: 'Votre organisation',
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            layout: 'form',
            title: 'Connexion',
            appTitle: 'Connexion',
        }
    },
    {
        path: '/recoverPassword',
        name: 'recoverPassword',
        component: RecoverPassword,
        meta: {
            layout: 'form',
            title: 'Mot de passe oublié',
            appTitle: 'Mot de passe oublié'
        }
    },
    {
        path: '/resetPassword/:token',
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            layout: 'form',
            title: 'Mot de passe oublié',
            appTitle: 'Mot de passe oublié',
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
            layout: 'form',
            title: 'Déconnexion',
            appTitle: 'Déconnexion'
        }
    },
    {
        path: '/signup',
        name: 'signup',
        component: Signup,
        meta: {
            layout: 'form',
            title: 'Inscription',
            appTitle: 'Inscription',
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            title: 'Utilisateurs',
            appTitle: 'Utilisateurs'
        }
    },
    {
        path: '/create/user',
        name: 'createUser',
        component: CreateUser,
        meta: {
            title: 'Créer un nouvel utilisateur',
            appTitle: 'Créer un nouvel utilisateur'
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: 'Votre profil',
            appTitle: 'Votre profil',
            requiresAuth: true
        }
    },
]

export default routes