<template>
  <ul class="sidebar-menu">
    <li :class="isActive(item)" v-for="(item, i) in items" :key="i">
      <a :href="'#' +item.url" :class="sidebarStatus ? 'center' : ''" @click.prevent="go(item.url)">
        <div class="menu-item-icon">
          <font-awesome-icon class="icon" :icon="item.icon" />
        </div>
        <div v-if="!sidebarStatus" v-html="item.text"></div>
      </a>
      <ul class="children" v-if="item.children">
        <li v-for="(child, i) in item.children" :key="i">
          <a @click.prevent="go(child.url)" href="#">
            {{child.text}}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user();
    },
    items() {
      return this.$store.getters.sidebarMenu.filter(item => {
        if (item.roles) {
          return item.roles.includes(this.user.role);
        }
        return true;
      });
    },
    sidebarStatus() {
      return this.$store.getters.sidebarStatus;
    }
  },
  methods: {
    isActive(item) {
      return item.url == this.$route.path ? "active" : "";
    },
    go(url) {
      this.$router.push(url);
    }
  }
};
</script>

<style lang="scss">
.sidebar-menu {
  //border-top: 1px solid $primary-border;
  > li {
    display: block;
    &:hover,
    &.active {
      > a {
        color: #fff;
        background-color: rgba(0, 0, 0, 0.85);
        .icon {
          color: $primary;
        }
      }
    }
    > a {
      display: flex;
      align-items: center;
      color: #b5b5b5;
      text-decoration: none;
      padding: $sidebarSpacer 15px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      line-height: 1.25;
      cursor: pointer;
      &.center {
        justify-content: center;
      }
      .menu-item-icon {
        padding-right: 15px;
        svg {
        }
      }
      > div {
      }
    }
  }
}
.sidebar-menu {
  .children {
    li {
      &:hover {
        background-color: rgba(0, 0, 0, 0.85);
      }
      a {
        color: #fff;
        padding:10px 0;
        padding-left:40px;
        display: block;
        text-decoration: none;
      }
    }
  }
}
</style>
