<template>
  <div id="jobs">
    <v-client-table refs="jobTables" v-if="jobs" class="etalent-table" :options="options" name="jobsTable" @row-click="rowClicked" :data="jobs" :columns="columns">
      <div slot="check" slot-scope="props">
        <label class="checkbox-container">
          <input :checked="isChecked(props.row.id)" @input="checkboxChanged($event, props.row.id)" type="checkbox">
          <span class="checkmark"></span>
        </label>
      </div>
      <div slot="created_at" slot-scope="props">
       {{props.row.created_at | moment("DD/MM/YYYY")}}
      </div>
      <div slot="title" slot-scope="props">
        <router-link :to="`/jobs/${props.row.id}/candidacies`" v-html="props.row.title"></router-link>
      </div>
      <!--<div slot="society" slot-scope="props">
        <div v-if="props.row.department" class="has-avatar">
          <div class="table-avatar" :style="setBg(props.row.department.society.photo)"></div>
          <span v-html="props.row.department.society.name"></span>
        </div>
      </div>
      <div slot="department" slot-scope="props">
        <div v-if="props.row.department" class="has-avatar">
          <div class="table-avatar" :style="setBg(props.row.department.photo)"></div>
          <span v-html="props.row.department.name"></span>
        </div>
      </div>-->
      <div slot="user" slot-scope="props">
        <div v-if="props.row.user" class="has-avatar">
          <div class="table-avatar" :style="setBg(props.row.user.imgUrl)"></div>
          <span v-html="props.row.user.username"></span>
        </div>
      </div>
      <div slot="closed" slot-scope="props">
        {{props.row.closed ? 'Clotûrée' : 'Publique'}}
      </div>
      <ul class="tools" slot="tools" slot-scope="props">
        <li v-tooltip="'Voir les candidatures'" @click="showCandidacies(props.row.id)">
          <font-awesome-icon icon="file-alt" class="icon"></font-awesome-icon>
        </li>
        <li v-tooltip="'Voir l\'offre'">
          <a target="_blank" :href="`http://www.adventuregroup.fr/offre/${props.row.id}`">
            <font-awesome-icon icon="eye" class="icon"></font-awesome-icon></a>
         
        </li>
        <li v-if="isAdmin" v-tooltip="'Editer l\'offre'" @click="edit(props.row.id)">
          <font-awesome-icon icon="edit" class="icon"></font-awesome-icon>
        </li>
        <li v-if="isAdmin" v-tooltip="'Dupliquer l\'offre'" @click="duplicate(props.row.id)">
          <font-awesome-icon icon="clone" class="icon"></font-awesome-icon>
        </li>
        <!--<li v-if="isAdmin" v-tooltip="'Partager l`\'offre'" @click="share(props.row.id)">
          <font-awesome-icon icon="share" class="icon"></font-awesome-icon>
        </li>-->
        <li v-if="isAdmin" v-tooltip="'Ajouter une candidature'" @click="addCandidacy(props.row.id)">
          <font-awesome-icon icon="plus" class="icon"></font-awesome-icon>
        </li>
        <li v-if="isAdmin" v-tooltip="'Voir les statistiques'" @click="showStats(props.row.id)">
          <font-awesome-icon icon="chart-line" class="icon"></font-awesome-icon>
        </li>
        <!--<li v-if="isAdmin && props.row.title !== 'Liste des candidatures spontanées'" v-tooltip="'Supprimer l\'offre'" @click="deleteJob(props.row.id)">
          <font-awesome-icon icon="trash-alt" class="icon"></font-awesome-icon>
        </li>-->
        <li v-if="isAdmin && !props.row.closed && props.row.title !== 'Liste des candidatures spontanées'" v-tooltip="'Clotûrer l\'offre'" @click="toggleJob(props.row.id, true)">
          <font-awesome-icon icon="archive" class="icon"></font-awesome-icon>
        </li>
        <li v-if="isAdmin && props.row.closed && props.row.title !== 'Liste des candidatures spontanées'" v-tooltip="'Réouvrir l\'offre'" @click="toggleJob(props.row.id, false)">
          <font-awesome-icon icon="envelope-open" class="icon"></font-awesome-icon>
        </li>
      </ul>
    </v-client-table>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import DELETE_JOB from "@/api/mutations/Job/deleteJob.graphql";
import TOGGLE_JOB from "@/api/mutations/Job/toggleJob.graphql";
import Vue from "vue";
export default {
  data() {
    return {
      closed: parseInt(this.$route.params.closed),
      selectedJobs: {},
      columns: [
        "check",
        "title",
        "created_at",
        "societyName",
        "departmentName",
        "type",
        "user",
        "tools"
      ]
    };
  },
  computed: {
    allSocietiesOptions() {
      var societies = this.$store.getters.societies() || [];
      return societies.map(society => {
        return {
          text: society.name,
          id: society.name
        };
      });
    },
    allDepartmentsOptions() {
      return this.$store.getters.allDepartments().map(department => {
        return {
          text: department.name,
          id: department.name
        };
      });
    },
    options() {
      var initFilters = {};
      var query = this.$route.query;
      for (let key in query) {
        initFilters[key] = query[key];
      }
      return {
        filterByColumn: true,
        columnsDropdown: true,
        initFilters,
        filterable: ["title", "societyName", "departmentName", "type", "user"],
        listColumns: {
          societyName: this.allSocietiesOptions || [],
          departmentName: this.allDepartmentsOptions || [],
          type: [
            {
              text: "Stage",
              id: "Stage"
            },
            {
              text: "Emploi",
              id: "Emploi"
            }
          ]
        },
        headings: {
          check: h => {
            return (
              <label class="checkbox-container">
                <input onInput={this.checkAll} type="checkbox" />
                <span class="checkmark" />
              </label>
            );
          },
          id: "id",
          title: "Nom",
          type: "Type",
          user: "Auteur",
          created_at: "Créé le",
          departmentName: "Département",
          societyName: "Société",
          department: "Département",
          tools: "Outils"
        }
      };
    },
    jobs() {
      return this.$store.getters.jobs();
    },
    user() {
      return this.$store.getters.user();
    },
    isAdmin() {
      return this.$store.getters.isAdmin();
    },
    societies() {
      return this.$store.getters.societies();
    }
  },
  methods: {
    duplicate(id) {
      this.$router.push({
        name: "create-job",
        query: {
          duplicate: id
        }
      });
    },
    checkboxChanged(event, id) {
      if (event.target.checked) {
        Vue.set(this.selectedJobs, id, true);
      } else {
        Vue.delete(this.selectedJobs, id);
      }
    },
    isChecked(id) {
      return Object.keys(this.selectedJobs).includes(id.toString());
    },
    checkAll(event) {
      this.jobs.map(item => {
        if (event.target.checked) {
          Vue.set(this.selectedJobs, item.id, true);
        } else {
          Vue.delete(this.selectedJobs, item.id);
        }
      });
    },
    edit(id) {
      this.$router.push(`/jobs/${id}/edit`);
    },
    show(id) {
      this.$router.push(`/jobs/${id}`);
    },
    share(id) {},
    showCandidacies(id) {
      this.$router.push(`/jobs/${id}/candidacies`);
    },
    addCandidacy(id) {
      this.$router.push(`/create/candidacy/${id}`);
    },
    deleteJob(id) {
      client
        .mutate({
          mutation: DELETE_JOB,
          variables: {
            jobId: id
          }
        })
        .then(res => {
          this.fetchJobs();
        })
        .catch(e => {});
    },
    toggleJob(id, closed) {
      client
        .mutate({
          mutation: TOGGLE_JOB,
          variables: {
            jobId: id,
            closed
          }
        })
        .then(res => {
          this.fetchJobs();
        })
        .catch(e => {});
    },
    showStats(id) {},
    fetchJobs(offset = 0) {
      this.$store.dispatch("fetchJobs", {
        offset,
        closed: this.closed ? true : false
      });
    },
    onLoaded() {},
    rowClicked({ row, event }) {
      //this.$router.push(`/jobs/${row.id}`);
    }
  },
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "plus-square",
      text: "Nouvelle offre",
      roles: ["admin"],
      click: () => {
        this.$router.push("/create/job");
      }
    });
    this.fetchJobs();
    this.$store.dispatch("fetchSocieties");
  }
};
</script>

<style lang="scss">
</style>
