<template>
  <a :style="btnStyle()" class="custom-btn">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#fff"
    },
    backgroundColor: {
      type: String,
      default: "#008BDD"
    },
    borderColor: {
      type: String,
      default: "#008BDD"
    },
    hoverColor: {},
    padding: {
      type: String,
      default: "10px 15px"
    },
    radius: {
      type: String,
      default: "3px"
    },
    upper: {
      type: Boolean,
      default: false
    },
    shadow: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    btnStyle() {
      return {
        border: `1px solid ${this.borderColor}`,
        backgroundColor: this.backgroundColor,
        color: this.color,
        padding: this.padding,
        borderRadius: this.radius,
        textTransform: this.upper ? "uppercase" : "",
        boxShadow: this.shadow ? "rgba(0, 0, 0, 0.37) 0px 3px 2px 0px" : ""
      };
    }
  }
};
</script>

<style lang="scss">
.custom-btn {
  display: block;
  border-radius: 3px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
}
</style>
