<template>
  <div :style="spacerStyle()" class="spacer">
  </div>
</template>

<script>
export default {
  props: {
    lvl: {
      type: Number,
      default: 2
    }
  },
  methods: {
    spacerStyle() {

      return {
        height: this.lvl * 10 + "px"
      };
    }
  }
};
</script>

<style>
</style>
