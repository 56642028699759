<template>
  <div class="reviews-candidacy">
    <card v-if="reviews">
      <template slot="header">
        <font-awesome-icon class="icon" icon="star"></font-awesome-icon>
        <h2>Avis sur la candidature</h2>
        <div class="cta">
          <Stars size="25px" :score="averageScore" />
          <b-dropdown v-if="hasVoted.length == 0" variant="primary" text="Ajouter un avis" class="m-2">
            <b-dropdown-text class="center">
              <StarsForm @scoreChanged="createReview"/>
            </b-dropdown-text>
          </b-dropdown>
        </div>
      </template>
      <ul class="list">
        <li v-for="review in reviews" :key="review.id">
          <div :style="setBg(review.user.imgUrl)" class="avatar"></div>
          <div class="item">
            <Stars :score="review.score" />
            <span class="subinfo">{{review.created_at | moment("from", "now")}}</span>
          </div>
          <div v-if="review.user.id === user.id">
            <a v-tooltip="'Supprimer votre avis'" href="#" @click.prevent="deleteReview(review.id)" class="trash-review">
              <font-awesome-icon class="icon" icon="trash"></font-awesome-icon>
            </a>
          </div>
        </li>
      </ul>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import DELETE_REVIEW from "@/api/mutations/Review/deleteReview.graphql";
import CREATE_REVIEW from "@/api/mutations/Review/createReview.graphql";

export default {
  props: {
    reviews: {
      type: Array,
      default: null
    },
    id: {
      type: Number,
      default: null
    }
  },
  computed: {
    hasVoted() {
      return this.reviews.filter(review => {
        return review.user.id === this.user.id
      })
    },
    user() {
      return this.$store.getters.user();
    },
    averageScore() {
      let sum = 0;
      this.reviews.forEach(review => {
        sum += review.score;
      });
      return Math.round(sum / this.reviews.length);
    }
  },
  methods: {
    async deleteReview(id) {
      let res = await client.mutate({
        mutation: DELETE_REVIEW,
        variables: { id }
      });
      this.$store.dispatch("fetchCandidacy", this.id)
    },
    async createReview(score) {
      let res = await client.mutate({
        mutation: CREATE_REVIEW,
        variables: {
          candidacyId: this.id,
          score
        }
      })
      this.$store.dispatch("fetchCandidacy", this.id)
    }
  }
};
</script>

<style lang="scss">
.trash-review {
  color: $error;
  &:hover {
    color: $error-D;
  }
}
</style>
