<template>
  <div id="latest-jobs">
    <card>
      <template slot="header">
        <font-awesome-icon class="icon" icon="user"></font-awesome-icon>
        <h2>Candidatures récentes</h2>
      </template>
      <ul class="list">
        <li v-for="candidacy in latestCandidacies" :key="candidacy.id">
          <div>
            <!--<Avatar v-if="j.user" :img="job.user.imgUrl" />-->
          </div>
          <div>
            <router-link :to="`/candidacies/${candidacy.id}`" v-html="candidacy.name"></router-link>
            a postulé sur l'offre {{candidacy.job.title}} <p class="subinfo"> crée {{candidacy.created_at | moment("from", "now")}}</p>
          </div>

        </li>
      </ul>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import CANDIDACIES from "@/api/queries/Candidacy/candidacies.graphql";
export default {
  data() {
    return {
      latestCandidacies: null
    };
  },
  methods: {
    async fetchCandidacies() {
      let res = await client.query({
        query: CANDIDACIES,
        variables: {
          offset: 0,
          limit: 6
        }
      });
      this.latestCandidacies = res.data.candidacies;
    }
  },
  mounted() {
    this.fetchCandidacies();
  }
};
</script>

<style lang="scss">
</style>
