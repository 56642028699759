<template>
  <div>
    <input type="text" v-model="form.name">
    <input type="text" v-model="form.mail">
    <input type="file" v-on:change="setResume($event)">
    <input type="file" v-on:change="setMotivation($event)">
    <button @click="submitForm()">Envoyer</button>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default {
  data() {
    return {
      formData: new FormData(),
      form: {
        name: "Name",
        mail: "mail@mail.fr",
        motivation: "",
        resume: "",
        phone: "dzadza",
        firstname: "dzadza",
        body: "dazdza"
      }
    };
  },
  methods: {
    setResume(e) {
      this.form.resume = e.target.files[0];
    },
    setMotivation(e) {
      this.form.motivation = e.target.files[0];
    },
    submitForm() {
      Object.keys(this.form).map(key => {
        this.formData.append(key, this.form[key]);
      });
      axios({
        method: "post",
        url: "http://localhost:8082/apply/1",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style>
</style>
