<template>
  <div v-if="society" id="society">
    <v-client-table class="etalent-table" :options="options" name="departmentsTable" :data="society.departments" :columns="columns">
      <div slot="name" slot-scope="props">
        <div class="has-avatar">
          <div class="table-avatar" :style="setBg(props.row.photo)"></div>
          <span v-html="props.row.name"></span>
        </div>
      </div>
      <ul class="tools" slot="tools" slot-scope="props">
        <li v-tooltip="'Modifier le département'" @click="edit(props.row.id)">
          <font-awesome-icon icon="edit" class="icon"></font-awesome-icon>
        </li>
        <li v-tooltip="'Supprimer le département'" @click="deleteDepartment(props.row.id)">
          <font-awesome-icon icon="trash-alt" class="icon"></font-awesome-icon>
        </li>
      </ul>
    </v-client-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      options: {
        filterByColumn: true,
        filterable: ["name"],
        headings: {
          name: "Nom",
          departments: "Nombre de départements",
          tools: "Outils"
        }
      }
    };
  },
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "plus-square",
      text: "Créer un nouveau département",
      roles: ["admin"],
      click: () => {
        this.$router.push(`/create/department/${this.id}`);
      }
    });
    this.fetchSociety();
  },
  computed: {
    columns() {
      return ["name", ...(this.isAdmin ? ["tools"] : [])];
    },
    isAdmin() {
      return this.$store.getters.isAdmin();
    },
    society() {
      return this.$store.getters.society();
    }
  },
  methods: {
    fetchSociety() {
      this.$store.dispatch("fetchSociety", this.id).then(() => {
        this.$store.commit("SET_APP_TITLE", `Société "${this.society.name}"`);
        document.title = `Société ${this.society.name} - eTalent`;
      });
    },
    edit(departmentId) {
      this.$router.push(
        `/societies/${this.id}/department/${departmentId}/edit`
      );
    },
    show(departmentId) {
      this.$router.push(`/societies/${this.id}/department/${departmentId}`);
    },
    deleteDepartment(id) {
      this.$store.dispatch("deleteDepartment", { id, societyId: this.id });
    }
  }
};
</script>

<style lang="scss">
</style>
