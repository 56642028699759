import Folders from '@/views/Folders/Folders.vue';
import Folder from '@/views/Folders/Folder.vue';
import CreateFolder from '@/views/Folders/Create.vue';

const routes = [
    {
        path: '/folders',
        name: 'folders',
        component: Folders,
        meta: {
            title: 'Dossiers',
            appTitle: 'Dossiers',
            requiresAuth: true
        }
    },
    {
        path: '/folders/:id',
        name: 'folder',
        component: Folder,
        meta: {
            title: 'Dossier',
            appTitle: 'Dossier',
            requiresAuth: true
        }
    },
    {
        path: '/create/folder',
        name: 'create-folder',
        component: CreateFolder,
        meta: {
            title: "Création d'un nouveau dossier",
            appTitle: "Création d'un nouveau dossier",
            requiresAdmin: true
        }
    },
]

export default routes