<template>
  <div id="edit-department">
    <card class="pa-4">
      <form v-if="department">
        <b-form-group label="Nom du département">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="department.name" placeholder="Indiquez le nom du département"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Logo du département">
          <upload-file v-model="department.photo" />
        </b-form-group>
        <div class="center">
          <b-btn @click="updateDepartment()" append variant="primary">Modifier le département</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      societyId: this.$route.params.id,
      departmentId: this.$route.params.departmentId
    };
  },
  computed: {
    department() {
      return this.$store.getters.department();
    }
  },
  mounted() {
    this.$store.dispatch("fetchDepartment", this.departmentId).then(res => {
      document.title = `Departement ${this.department.name} - eTalent`;
      this.$store.commit("SET_APP_TITLE", `Modficiation du départment "${this.department.name}"`);
    });
    this.$store.commit("SET_CTA", {
      icon: "archive",
      text: "Annuler la modification",
      type: "error",
      click: () => {
        this.$router.push(`/societies/${this.societyId}`);
      }
    });
  },
  methods: {
    updateDepartment() {
      this.$store
        .dispatch("updateDepartment", this.department)
        .then(res => {
          if (res) {
            this.$router.push(`/societies/${this.societyId}`);
            this.$store.dispatch("pushNotif", {
              type: "success",
              message: "Le département a bien été modifié"
            });
          } else {
            this.$store.dispatch("pushNotif");
          }
        })
        .catch(err => {
          this.$store.dispatch("pushNotif");
        });
    }
  }
};
</script>

<style>
</style>
