<template>
  <div class="pdf-viewer">
    <ul>
      <li>
        <a href="#" @click="getPdf(motivation)">Voir la lettre de motivation</a>
      </li>
      <li>
        <a href="#" @click="getPdf(resume)">Voir le CV</a>
      </li>
    </ul>
    <div class="pdf-tools">
      <a href="#" :class="page == 1 ? 'disabled' : ''" @click.prevent="page > 1 ? page-- : 1">
        <font-awesome-icon class="icon" icon="chevron-left"></font-awesome-icon>
        Page précédente
      </a>
      <span>
        {{page}} / {{ numPages ? numPages : '∞' }}
      </span>
      <a :class="page >= numPages ? 'disabled' : ''" href="#" @click.prevent="page < numPages ? page++ : 1">
        Page suivante
        <font-awesome-icon class="icon" icon="chevron-right"></font-awesome-icon>
      </a>
    </div>
    <pdf :key="pdfData.docId" scale.sync="page-width" style="width:100%;" v-if="pdfData" :src="pdfData" :page="page">
      <template slot="loading">
        loading content here...
      </template>
    </pdf>
  </div>
</template>

<script>
import pdfvuer from "pdfvuer";
export default {
  props: {
    resume: {
      type: String,
      default: null
    },
    motivation: {
      type: String,
      default: null
    }
  },
  components: {
    pdf: pdfvuer
  },
  data() {
    return {
      page: 1,
      pdfData: null,
      numPages: 0
    };
  },
  methods: {
    getPdf(pdf) {
      this.pdfData = pdfvuer.createLoadingTask(pdf);
      this.pdfData.then(pdf => {
        this.numPages = pdf.numPages;
        this.$forceUpdate();
      });
    }
  },
  mounted() {
    console.log(this.resume)
    this.getPdf(this.resume);
  }
};
</script>

<style lang="scss">
.pdf-viewer {
  &::-webkit-scrollbar {
    width: 22px;
    margin-right: 10px;
  }
  &::-webkit-scrollbar-track {
    margin: 5px 0;
    box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.2);
    border: solid 8px transparent;
    border-radius: 30px;
  }
  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px $primary;
    border: solid 8px transparent;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    box-shadow: inset 0 0 10px 10px $primary-D;
    border: solid 6px transparent;
  }
  overflow: scroll;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: $box-shadow;
  ul {
    display:flex;
    align-items: center;
    justify-content: center;
    margin:0;
    li {
      margin:8px 10px;
      a {

      }
    }
  }
  .pdf-tools {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    a {
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        margin: 0 10px;
      }
      &.disabled {
        color: $text;
        opacity: 0.4;
        cursor: default;
      }
    }
  }
}
</style>
