import { client } from '@/apollo/init.js'
import Vue from 'vue'

import TAGS from "@/api/queries/Tag/tags.graphql"
import TAG from "@/api/queries/Tag/tag.graphql"
import CREATE_TAG from "@/api/mutations/Tag/createTag.graphql";
import DELETE_TAG from "@/api/mutations/Tag/deleteTag.graphql";
import UPDATE_TAG from "@/api/mutations/Tag/updateTag.graphql";

const defaultState = {
    tags: [],
    tag: null
};

const actions = {
    fetchTags: (context) => {
        return client.query({
            query: TAGS
        }).then(res => {
            context.commit('SET_TAGS', res.data.tags)
        }).catch(e => {
            console.log(e)
        })
    },
    fetchTag: (context, id) => {
        return client.query({
            query: TAG,
            variables: {
                id
            }
        }).then(res => {
            context.commit('SET_TAG', res.data.tag)
        }).catch(e => {
            console.log(e)
        })
    },
    createTag: (context, tagInput) => {
        return client.mutate({
            mutation: CREATE_TAG,
            variables: {
                tagInput
            }
        }).then(res => {
            return res.data.createTag.ok
        }).catch(err => {
            console.log(err)
            return false
        })
    },
    updateTag: (context, tag) => {
        let { title, id, color } = tag
        return client.mutate({
            mutation: UPDATE_TAG,
            variables: {
                tagInput: {
                    title,
                    color
                },
                id
            }
        })
    },
    deleteTag: (context, id) => {
        return client.mutate({
            mutation: DELETE_TAG,
            variables: {
                id
            }
        }).then(res => {
            return res.data.deleteTag.ok
        }).catch(err => {
            console.log(err)
            return false
        })
    }
};

const mutations = {
    SET_TAGS: (state, payload) => {
        state.tags = payload
    },
    SET_TAG: (state, payload) => {
        state.tag = payload
    },
};

const getters = {
    tags: (state) => () => state.tags,
    tag: (state) => () => state.tag,
    /*foldersOptions: state => () => {
        if (state.folders) {
            let options = state.folders.map((folder) => {
                return {
                    text: folder.name,
                    value: folder.id
                }
            })
            options.unshift({
                text: "Aucun",
                value: null
            })
            return options
        }
    },*/
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
