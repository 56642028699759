<template>
  <form ref="jobForm" v-if="job">
    <b-form-group label="Nom de l'offre">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="address-book"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input required v-model="job.title" placeholder="Indiquez le nom de l'offre"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Header de l'offre * ">
      <vue-editor v-model="job.header"></vue-editor>
    </b-form-group>

    <b-form-group label="Texte de présentation de l'offre *">
      <vue-editor v-model="job.body"></vue-editor>
    </b-form-group>

   

    <b-form-group label="Société *">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="building"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-select required v-model="societyId" :options="societiesOptions"></b-form-select>
      </b-input-group>
    </b-form-group>

    <b-form-group v-if="societyId" label="Département *">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="building"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-select required v-model="departmentId" :options="departmentsOptions"></b-form-select>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Type d'offre *">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="building"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-select required v-model="job.type" :options="typesOptions"></b-form-select>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Missions *">
      <vue-editor v-model="job.missions"></vue-editor>
    </b-form-group>

    <b-form-group label="Profil *">
      <vue-editor v-model="job.profil"></vue-editor>
    </b-form-group>

    <b-form-group label="Localisation de l'offre">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="map-marker-alt"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input required v-model="job.place" placeholder="Ville ou code postal"></b-form-input>
      </b-input-group>
    </b-form-group>
    
    <b-form-group label="Date de début du contrat">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="calendar-check"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input v-model="job.startAt" placeholder="Indiquez la date de début de l'offre"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Durée du contrat">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="clock"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input v-model="job.duration" placeholder="Durée du contrat"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Type de contrat *">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="file"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-select required v-model="job.contract" :options="contractsOptions"></b-form-select>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Rémunération">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="dollar-sign"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input v-model="job.salary" placeholder="Rémunération"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Disponibilités">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="battery-three-quarters"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input v-model="job.availability" placeholder="Disponibilités"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Volume horaire hebdomadaire">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="calendar"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input v-model="job.info" placeholder="Disponibilités"></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group label="Photo de présentation de l'offre">
      <upload-file v-model="job.imgUrl" />
    </b-form-group>
    <div class="center">
      <b-btn @click="processForm()" append variant="primary" v-html="btnLabel"></b-btn>
    </div>
  </form>

</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor
  },
  props: {
    btnLabel: {
      type: String,
      default: "Enregistrer"
    }
  },
  data() {
    return {
      id: this.$route.params.id,
      departmentId: null,
      societyId: null
    };
  },
  computed: {
    gmapLoaded() {
      return this.$store.getters.gmapLoaded;
    },
    job() {
      return this.$store.getters.job();
    },
    societiesOptions() {
      return this.$store.getters.societiesOptions();
    },
    departmentsOptions() {
      return this.$store.getters.departmentsOptions(this.societyId);
    },
    typesOptions() {
      return this.$store.getters.typesOptions();
    },
    contractsOptions() {
      return this.$store.getters.contractsOptions();
    }
  },
  watch: {
    societyId(oldVal, newVal) {
      if (oldVal && newVal) {
        this.departmentId = null;
      }
    }
  },
  methods: {
    processForm() {
      if (this.$refs.jobForm.checkValidity()) {
        this.$emit("saveForm", {
          departmentId: this.departmentId,
          job: this.job,
          id: this.id
        });
      }
      else {
        this.$refs.jobForm.reportValidity();
      }
    },
    clearIt(vals) {
      if (vals.newVal === "") {
        this.job.place = null;
      }
    },
    getAddressData(addressData, place, id) {
      this.job.place = place.formatted_address;
    }
  },
  mounted() {
    this.$store.dispatch("fetchJob", this.id || 0).then(res => {
      if (res.data.job) {
        this.departmentId = this.job.department.id;
        this.societyId = this.job.department.society.id;
      }
    });
    this.$store.dispatch("fetchSocieties");
  }
};
</script>

<style>
</style>
