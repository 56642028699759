<template>
  <div class="mobile-navbar">
    <ul>
      <li>
        <font-awesome-icon class="navbar-icon" @click="$router.go(-1)" icon="angle-left" />
      </li>
      <li>
        <h3 v-html="pageTitle"></h3>
      </li>
      <li class="spacer">
      </li>
      <li>
        <a v-if="cta.text && visible" :class="cta.type" class="cta" href="#" @click.prevent="ctaClicked()">
          <font-awesome-icon class="icon" :icon="cta.icon"></font-awesome-icon>
          <span v-html="cta.text"></span>
        </a>
      </li>
    </ul>
  </div>

</template>

<script>
import UserPreview from "@/components/Board/UserPreview";
export default {
  components: {
    UserPreview
  },
  computed: {
    user() {
      return this.$store.getters.user();
    },
    pageTitle() {
      return this.$store.getters.appTitle;
    },
    sidebarStatus() {
      return this.$store.getters.sidebarStatus;
    },
    cta() {
      return this.$store.getters.cta;
    },
    visible() {
      if(this.cta.roles) {
        return this.cta.roles.includes(this.user.role)
      }
      return true
    }
  },
  methods: {
    toggleSidebar() {
      this.$store.commit("TOGGLE_SIDEBAR");
    },
    ctaClicked(event) {
      this.cta.click();
    }
  }
};
</script>

<style lang="scss">
.mobile-navbar {
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100%;
  box-shadow: $box-shadow;
  ul {
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 10px 15px;
    align-items: center;
    position: relative;
    z-index: 2;
    margin: 0;
    > li {
      color: $text;
      padding: 0 10px;
      h3 {
        color: $text;
        margin: 0;
      }
      .navbar-icon {
        font-size: 28px;
        cursor: pointer;
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
      &.spacer {
        flex: 1;
      }
    }
  }
  .cta {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    background-color: $secondary;
    padding: 15px 20px;
    border-radius: 4px;
    box-shadow: $box-shadow;
    text-transform: uppercase;
    font-weight: bold;
    .icon {
      margin-right: 10px;
    }
    &.error {
      background-color:$error;
      &:hover {
        background-color:$error-D;
      }
    }
    &.success {
      background-color:$success;
      &:hover {
        background-color:$success-D;
      }
    }
    &:hover {
      background-color: $secondary-D;
    }
  }
}
</style>
