const defaultState = {
    sidebarStatus: false,
    appTitle: "",
    gmap: false,
    cta: {
        text: "",
        icon: "plus",
        click: () => {

        }
    },
    sidebarMenu: [
        {
            text: "Accueil",
            url: "/",
            icon: "home",
        },

        {
            text: "Créer une offre",
            url: "/create/job",
            icon: "plus",
            roles: ['admin']
        },
        {
            text: "Offres clotûrées",
            url: "/1/jobs",
            icon: "list"
        },
        {
            text: "Candidatures (toutes)",
            url: "/candidacies",
            icon: "list"
        },
        {
            text: "Offres publiques",
            url: "/0/jobs",
            icon: "list",
            children: [{
                text: "Stages",
                url: "/0/jobs?type=Stage",
            },
            {
                text: "Emplois",
                url: "/0/jobs?type=Emploi"
            }]
        },
        {
            text: "Candidatures spontanées",
            url: "/jobs/6/candidacies",
            icon: "male",
            roles: ['admin']
        },
        /*{
            text: "Dossiers",
            url: "/folders",
            icon: "folder-open",
            roles: ['admin']
        },*/
        {
            text: "Tags",
            url: "/tags",
            icon: "tags",
            roles: ['admin']
        },
        {
            text: "Membres",
            url: '/users',
            icon: "users"
        },
        {
            text: "Votre Organisation",
            url: '/societies',
            icon: "sitemap"
        },
        {
            text: "Statistiques",
            url: "/stats",
            icon: "chart-line",
            roles: ['admin']
        },
        {
            text: "Votre profil",
            url: "/profile",
            icon: "user"
        },
        {
            text: "Rechercher",
            url: "/search",
            icon: "search"
        },
        {
            text: "Déconnexion",
            url: "/logout",
            icon: "sign-out-alt"
        },
    ]
};

const actions = {
};

const mutations = {
    TOGGLE_SIDEBAR: (state) => {
        state.sidebarStatus = !state.sidebarStatus
    },
    CLOSE_SIDEBAR: (state) => {
        state.sidebarStatus = false
    },
    OPEN_SIDEBAR: (state) => {
        state.sidebarStatus = true
    },
    SET_APP_TITLE: (state, payload) => {
        state.appTitle = payload
    },
    SET_CTA: (state, payload) => {
        state.cta = payload
    },
    RESET_CTA: (state, payload) => {
        state.cta = {}
    },
    GOOGLE_API_LOADED: (state) => {
        state.gmap = true
    }
};

const getters = {
    sidebarStatus: state => state.sidebarStatus,
    sidebarMenu: state => state.sidebarMenu,
    appTitle: state => state.appTitle,
    cta: state => state.cta,
    gmapLoaded: state => state.gmap
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
