<template>
  <div v-if="folders" id="folers">
    <v-client-table class="etalent-table" :options="options" name="foldersTable" :data="folders" :columns="columns">
      <div slot="candidaciesLength" slot-scope="props">
        {{props.row.candidacies.length}}
      </div>
      <div slot="name" slot-scope="props">
        <router-link :to="`/folders/${props.row.id}`">
          {{props.row.name || 'Aucun nom'}}
        </router-link>
      </div>
    </v-client-table>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  data() {
    return {
      columns: ["name", "candidaciesLength"],
      options: {
        filterByColumn: true,
        filterable: ["name"],
        headings: {
          name: "Nom du dossier",
          candidaciesLength: "Nombre de candidatures"
        }
      }
    };
  },
  computed: {
    folders() {
      return this.$store.getters.folders();
    }
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchFolders");
    this.$store.commit("SET_CTA", {
      icon: "plus-square",
      text: "Créer un nouveau dossier",
      click: () => {
        this.$router.push("/create/folder");
      }
    });
  }
};
</script>

<style lang="scss">
</style>
