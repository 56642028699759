import { client } from '@/apollo/init.js'
import Vue from "vue";
import JOBS from "@/api/queries/Job/jobs.graphql"
import JOB from "@/api/queries/Job/job.graphql"
import CREATE_JOB from "@/api/mutations/Job/createJob.graphql"
import UPDATE_JOB from "@/api/mutations/Job/updateJob.graphql"
import { extract } from "@/utils/functions"

const defaultState = {
    jobs: [],
    job: {},
    typesOptions: ['Stage', 'Emploi'],
    contractsOptions: ['CDI', 'CDD', 'Intérim', 'Freelance', 'A définir en fonction du profil', 'Stage']
};

const actions = {
    fetchJobs: (context, {offset, closed}) => {
        return client.query({
            query: JOBS,
            variables: {
                offset, 
                closed
            }
        }).then(res => {
            console.log(res.data)
            var jobs = res.data.jobs.map(job => {
                return {
                    ...job,
                    societyName: job.department ? job.department.society.name : null,
                    departmentName: job.department ? job.department.name : null
                }
            })
            context.commit('PUSH_JOBS', jobs)
        }).catch(e => {
            console.log(e)
        })
    },
    fetchJob: (context, id) => {
        return client.query({
            query: JOB,
            variables: {
                id
            }
        }).then(res => {
            context.commit('SET_JOB', res.data.job || {})
            return res
        })
    },
    createJob: (context, { jobInput, departmentId }) => {
        let job = extract(jobInput, ['user', 'id', 'department'])
        return client.mutate({
            mutation: CREATE_JOB,
            variables: { jobInput: job, departmentId }
        }).then(res => {
            return res.data.createJob.ok
        })
    },
    updateJob: (context, { jobInput, jobId, departmentId }) => {
        let job = extract(jobInput, ['user', 'id', 'department'])
        return client.mutate({
            mutation: UPDATE_JOB,
            variables: { jobInput: job, jobId, departmentId }
        }).then(res => {
            if (res.data.updateJob.ok) {
                context.dispatch('pushNotif', {
                    message: "L'offre a bien été mise à jour",
                    type: "success"
                })
            }
        }).catch(err => {
            console.log(err)
            context.dispatch('pushNotif')
        })
    },
};

const mutations = {
    PUSH_JOBS: (state, jobs) => {
        //state.jobs.push(...jobs)
        state.jobs = jobs
    },
    SET_JOB: (state, job) => {
        state.job = job
    }
};

const getters = {
    jobs: (state) => () => state.jobs,
    job: (state) => () => state.job,
    typesOptions: state => () => state.typesOptions,
    contractsOptions: state => () => state.contractsOptions,
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
