<template>
  <div id="job-edit">
    <card class="pa-4">
      <job-form btn-label="Créer l'offre" @saveForm="createJob"></job-form>
    </card>
  </div>
</template>

<script>
import JobForm from "@/components/Jobs/JobForm";
export default {
  components: {
    JobForm
  },
  data() {
    return {
      id: this.$route.params.id
    };
  },
  computed: {
    job() {
      return this.$store.getters.job();
    }
  },
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "archive",
      text: "Annuler l'offre",
      type: "error",
      click: () => {
        this.$router.go(-1);
      }
    });
    if (this.$route.query.duplicate) {
      this.$store.dispatch("fetchJob", this.$route.query.duplicate);
    }
  },
  methods: {
    createJob(data) {
      this.$store
        .dispatch("createJob", {
          jobInput: data.job,
          departmentId: data.departmentId
        })
        .then(res => {
          if (res) {
            this.$store.dispatch("pushNotif", {
              message: "L'offre a bien été créée",
              type: "success"
            });
            this.$router.push("/jobs/");
          } else {
            this.$store.dispatch("pushNotif");
          }
        })
        .catch(err => {
          this.$store.dispatch("pushNotif");
        });
    }
  }
};
</script>

<style lang="scss">
</style>
