<template>
  <ul class="stars">
    <li v-for="i in [1,2,3,4,5]" :key="i">
      <font-awesome-icon :style="{fontSize: size}" class="icon" :icon="[score >= i ? 'fas' : 'far', 'star']"></font-awesome-icon>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    score: {
      type: Number,
      default: 1
    },
    size: {
        type: String,
        default: "initial"
    }
  },
};
</script>

<style lang="scss">
.stars {
    display:flex;
    align-items: center;
    margin:0;
    padding:0;
    > li {
        .icon {
            color:#e7711b !important;
        }
    }
}
</style>
