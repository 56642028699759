<template>
  <div v-if="candidacy" id="other-candidacies">
    <card>
      <template slot="header">
        <font-awesome-icon class="icon" icon="user-circle"></font-awesome-icon>
        <h2>Autres candidatures de "{{candidacy.name}} {{candidacy.firstname}}" </h2>
      </template>
      <ul class="list" v-if="otherCandidacies">
        <li v-for="c in otherCandidacies" :key="c.id">
          <div :style="setBg(c.job.imgUrl)" class="avatar"></div>
          <div class="item">
            <p>{{c.name}} {{c.firstname}} a aussi postulé sur l'offre <router-link :to="`/jobs/${c.job.id}`" v-html="c.job.title"></router-link>
            </p>
            <span class="subinfo">{{c.created_at | moment("from", "now")}}</span>
          </div>
        </li>
      </ul>
      <div v-else>
        Ce candidat n'a pas postulé sur d'autres offres
      </div>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import OTHER_CANDIDACIES from "@/api/queries/Candidacy/otherCandidacies.graphql";
export default {
  props: {
    candidacy: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      otherCandidacies: null
    };
  },
  mounted() {
    client
      .query({
        query: OTHER_CANDIDACIES,
        variables: {
          mail: this.candidacy.mail,
          id: this.candidacy.id
        }
      })
      .then(res => {
        this.otherCandidacies = res.data.otherCandidacies;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>

<style lang="scss">
</style>
