<template>
  <div v-if="candidacy" class="candidacy-card">
    <card>
      <template slot="header">
        <font-awesome-icon class="icon" icon="user-circle"></font-awesome-icon>
        <h2>Informations principales</h2>
        <div v-if="isAdmin">
          <b-button :to="`/candidacies/${candidacy.id}/edit`" variant="primary">Modifier</b-button>
        </div>
      </template>
      <ul class="list">
        <li>
          <font-awesome-icon class="mr-2" icon="user"></font-awesome-icon>
          <span v-html="candidacy.name"></span>
        </li>
        <li>
          <font-awesome-icon class="mr-2" icon="user"></font-awesome-icon>
          <span v-html="candidacy.firstname"></span>
        </li>
        <li>
          <font-awesome-icon class="mr-2" icon="envelope"></font-awesome-icon>
          <span v-html="candidacy.mail"></span>
        </li>
        <li>
          <font-awesome-icon class="mr-2" icon="phone"></font-awesome-icon>
          <span v-html="candidacy.phone"></span>
        </li>
        <li>
          <font-awesome-icon class="mr-2" icon="cloud-download-alt"></font-awesome-icon>
          <a target="_blank" :href="candidacy.resume">Télécharger le CV</a>
        </li>
        <li>
          <font-awesome-icon class="mr-2" icon="cloud-download-alt"></font-awesome-icon>
          <a target="_blank" :href="candidacy.motivation">Télécharger la lettre de motivation</a>
        </li>
        <li>
          <font-awesome-icon class="mr-2" icon="comment"></font-awesome-icon>
          <span v-html="candidacy.body"></span>
        </li>
      </ul>
    </card>
  </div>
</template>

<script>
export default {
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin();
    }
  },
  props: {
    candidacy: {
      type: Object,
      default: null
    }
  }
};
</script>

<style>
</style>
