<template>
  <div v-if="department" id="department">
    <page-title>Département {{department.name}}</page-title>
    <input type="text" v-model="department.name">
    <btn class="my-4" @click.native="updateDepartment(department)">Enregistrer</btn>
  </div>
</template>


<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      departmentId: this.$route.params.departmentId
    };
  },
  mounted() {
    this.fetchDepartment();
  },
  computed: {
    department() {
      return this.$store.getters.department();
    }
  },
  methods: {
    fetchDepartment() {
      this.$store.dispatch("fetchDepartment", this.departmentId).then(res => {
        document.title = `Departement ${this.department.name} - eTalent`;
      });
    },
    updateDepartment(department) {
      this.$store.dispatch("updateDepartment", department);
    }
  }
};
</script>

<style lang="scss">
</style>
