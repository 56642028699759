<template>
  <div id="form-layout">
    <div class="form-w">
      <div class="form-title">
        <h1>Bienvenue sur eTalent</h1>
      </div>
      <div class="form-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    loading() {
      return this.$store.getters.isLoading ? "loading" : "";
    }
  }
};
</script>

<style lang="scss">

#form-layout {
  height: 100vh;
  background: linear-gradient(to bottom, $primary-D 0%, $primary 100%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .form-w {
    display: block;
    width: 500px;
    margin: auto;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.26);
    position: relative;
    padding-top: 20px;
    .form-logo {
      overflow: hidden;
      min-height:100px;
      img {
        width: 50%;
        transform: scale(1);
        margin: auto;
      }
    }
    .form-title {
      h1 {
        text-align: center;
        font-size: 1.5rem;
      }
    }
    .form-content {
      padding: 3em 2em;
    }
  }
}

@keyframes loading {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  to {
    transform: scale(1);
  }
}

#form-layout .form-w .form-logo img {
  .loading {
    animation: 3.4s linear 0s infinite alternate loading;
  }
}
</style>
