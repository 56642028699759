<template>
  <div class="user-preview">
    <div v-if="user" :style="{ backgroundImage: `url(${user.imgUrl}`}" @click="$router.push('/profile')" class="user-img">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      status: false
    };
  },
  computed: {
    user() {
      return this.$store.getters.user();
    }
  },
  methods: {}
};
</script>

<style lang="scss">
.user-preview {
  position: relative;
  .user-img {
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.4);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
  }
}
</style>
