import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, concat, split } from 'apollo-link'
import { onError } from "apollo-link-error";
import VueApollo from 'vue-apollo'
import store from '@/store'

const errorLink = onError(({ graphQLErrors }) => {
    console.log(graphQLErrors)
    if (graphQLErrors) graphQLErrors.map(({ message }) => {
        console.log(message)
        if(message == 'AUTH_401') {
            console.log('logout')
            store.dispatch('logout')
        }
    })
})

console.log( process.env.NODE_ENV);
const serverLink = new HttpLink({
    uri: process.env.NODE_ENV === 'development' ? 'https://etalent.herokuapp.com/graphql' : 'https://etalent.herokuapp.com/graphql',
})

const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext({
        headers: {
            'x-token': localStorage.getItem('x-token') ,
            'x-refresh-token': localStorage.getItem('x-refresh-token')
        }
    })
    return forward(operation)
})

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
    },
    notifyOnNetworkStatusChange: true
}

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authMiddleware, serverLink]),
    //link: authAfterware.concat(concat(authMiddleware, serverLink)),
    cache: new InMemoryCache(),
    defaultOptions,
    connectToDevTools: true,
})

const apolloProvider = new VueApollo({
    defaultClient: client,
    defaultOptions: {
        $loadingKey: 'loading'
    }
})

export {
    apolloProvider,
    client
}