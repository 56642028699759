<template>
  <div>
    <card class="pa-4">
      <form v-if="folder">
        <b-form-group label="Nom du dossier">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="file-alt"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="folder.name" placeholder="Indiquez le nom du dossier"></b-form-input>
          </b-input-group>
        </b-form-group>
        <div class="center">
          <b-btn @click="createFolder()" append variant="primary">Créer le dossier</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import JobForm from "@/components/Jobs/JobForm";
export default {
  components: {
    JobForm
  },
  data() {
    return {
      id: this.$route.params.id,
      folder: {
        name: ""
      }
    };
  },
  computed: {},
  mounted() {
    this.$store.commit("SET_CTA", {
      icon: "archive",
      text: "Annuler",
      type: "error",
      click: () => {
        this.$router.push("/folders");
      }
    });
  },
  methods: {
    createFolder() {
      this.$store.dispatch("createFolder", this.folder).then(res => {
        if (res) {
          this.$router.push("/folders");
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
