<template>
  <div id="dashboard">
    <b-button @click="publishJobsToWebsite()" variant="primary">Mettre à jour le site Adventure Group (5 minutes de délai)</b-button>
    <LatestActions />
    <LatestJobs />
    <LatestCandidacies />
    <GlobalStats />
  </div>
</template>

<script>
import LatestJobs from "@/components/Dashboard/LatestJobs";
import LatestActions from "@/components/Dashboard/LatestActions";
import LatestCandidacies from "@/components/Dashboard/LatestCandidacies";
import GlobalStats from "@/components/Dashboard/GlobalStats";
import axios from "axios";

export default {
  components: {
    LatestJobs,
    LatestCandidacies,
    GlobalStats,
    LatestActions
  },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.user();
    }
  },
  methods: {
    async publishJobsToWebsite() {
      await axios.post("https://api.netlify.com/build_hooks/63ce9f14cef8cb39af9d9f70")
      alert("Mise à jour du site déclenchée")
    }
  },
  mounted() {
    if (this.user.organization.name == "La Fraîcherie") {
      this.$store.commit("SET_CTA", {
        icon: "check",
        text: "Mettre à jour le site",
        click: () => {
          axios
            .post(
              "https://api.netlify.com/build_hooks/5d6580c5a9636558a0b0fcd9"
            )
            .then(res => {
              if (res.status == 200) {
                this.$store.dispatch("pushNotif", {
                  type: "success",
                  message: "Le site a bien été mis à jour"
                });
              } else {
                this.$store.dispatch("pushNotif");
              }
            })
            .catch(err => {
              console.log(err);
              this.$store.dispatch("pushNotif");
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
#dashboard {
}
</style>
