import { client } from '@/apollo/init.js';

import REGISTER from "@/api/mutations/User/register.graphql";
import RESET_PASSWORD from "@/api/mutations/User/resetPassword.graphql";
import RECOVER_PASSWORD from "@/api/mutations/User/recoverPassword.graphql";
import LOGIN from "@/api/mutations/User/login.graphql";
import UPDATE_USER from "@/api/mutations/User/updateUser.graphql";
import router from "@/router"
const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };


const defaultState = {
    user: user,
    status: user ? { loggedIn: true } : { loggedIn: false }
};

const actions = {
    register: (context, { userInput, organizationInput }) => {
        context.commit('UPDATE_LOADING', 1);
        return client.mutate({
            mutation: REGISTER,
            variables: {
                userInput, organizationInput
            }
        }).then(res => {
            context.commit('UPDATE_LOADING', -1);
            if (res.data.register.ok) {
                context.dispatch('login', userInput)
            }
            return res.data.register
        }).catch(e => {
            context.commit('UPDATE_LOADING', -1);
        })
    },
    login: (context, payload) => {
        context.commit('UPDATE_LOADING', 1);
        return client.mutate({
            mutation: LOGIN,
            variables: payload
        }).then(res => {
            context.commit('UPDATE_LOADING', -1);
            let { ok, token, refreshToken, user, errors } = res.data.login
            if (ok && token) {
                localStorage.setItem('user', JSON.stringify(user))
                localStorage.setItem('x-token', token)
                localStorage.setItem('x-refresh-token', refreshToken)
                context.commit('SET_USER', res.data.login.user)
            }
            return res.data.login
        }).catch(e => {
            console.log(e)
            context.commit('UPDATE_LOADING', -1);
        })
    },
    logout: (context) => {
        context.commit('UPDATE_LOADING', 1);
        return new Promise((resolve, reject) => {
            context.commit('SET_USER', null)
            localStorage.removeItem('user')
            localStorage.removeItem('x-token')
            localStorage.removeItem('x-refresh-token')
            context.commit('UPDATE_LOADING', -1);
            router.push("/login");
            resolve()
        })
    },
    resetPassword: (context, { password, token }) => {
        context.commit('UPDATE_LOADING', 1);
        return client.mutate({
            mutation: RESET_PASSWORD,
            variables: {
                password,
                token
            }
        }).then(res => {
            context.commit('UPDATE_LOADING', -1);
        }).catch(e => {
            console.log(e)
            context.commit('UPDATE_LOADING', -1);
        })
    },
    recoverPassword: (context, email) => {
        context.commit('UPDATE_LOADING', 1);
        return client.mutate({
            mutation: RECOVER_PASSWORD,
            variables: {
                email
            }
        }).then(res => {
            context.commit('UPDATE_LOADING', -1);
            return res.data.recoverPassword.ok
        }).catch(err => {
            context.commit('UPDATE_LOADING', -1);
            return err
        })
    },
    updateUser: (context, user) => {
        let { username, password, email, imgUrl, name, firstname } = user
        return client.mutate({
            mutation: UPDATE_USER,
            variables: {
                userInput: {
                    username, password, email, imgUrl, name, firstname
                }
            }
        }).then(res => {
            if (res.data.updateUser.ok) {
                let updatedUser = res.data.updateUser.user
                context.commit('SET_USER', updatedUser)
                context.dispatch('pushNotif', { type: "success", message: "Votre profil a bien été enregistré" })
                localStorage.setItem('user', JSON.stringify(updatedUser))
            }
            else {
                context.dispatch('pushNotif', { type: "error", message: res.data.updateUser.errors[0].message })
            }
        }).catch(err => {
            console.log(err)
            context.dispatch('pushNotif')
        })
    }
};

const mutations = {
    SET_USER: (state, user) => {
        state.user = user
    }
};

const getters = {
    user: state => () => state.user,
    isAdmin: state => () => state.user.role === 'admin',
    isManager: state => () => state.user.role === 'manager',
    isManagerOrAdmin: state => () => state.user.role === 'manager' || state.user.role === 'admin'
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
