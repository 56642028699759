<template>
  <div id="job-edit">
    <card class="pa-4">
      <job-form btn-label="Enregistrer" @saveForm="updateJob"></job-form>
    </card>
  </div>
</template>

<script>
import JobForm from "@/components/Jobs/JobForm";
export default {
  components: {
    JobForm
  },
  data() {
    return {
      id: this.$route.params.id
    };
  },
  computed: {
    job() {
      return this.$store.getters.job();
    }
  },
  mounted() {
    this.$store.dispatch("fetchJob", this.id);
    this.$store.commit("SET_CTA", {
      icon: "eye",
      text: "Voir l'offre",
      type: "info",
      click: () => {
        this.$router.push(`/jobs/${this.id}`)
      }
    });
  },
  methods: {
    updateJob(data) {
      this.$store.dispatch("updateJob", {
        jobInput: data.job,
        jobId: data.id,
        departmentId: data.departmentId
      });
    }
  }
};
</script>

<style lang="scss">
</style>
