<template>
  <div id="create-candidacy">
    <card class="pa-4">
      <form>
        <b-form-group label="Nom du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.name" placeholder="Indiquez le nom du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Prénom du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.firstname" placeholder="Indiquez le prénom du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Adresse email du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.mail" placeholder="Indiquez l'adresse email du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Numéro de téléphone du candidat">
          <b-input-group>
            <b-input-group-prepend is-text>
              <font-awesome-icon icon="building"></font-awesome-icon>
            </b-input-group-prepend>
            <b-form-input v-model="candidacy.phone" placeholder="Indiquez le numéro de téléphone du candidat"></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Commentaires du candidat">
          <b-form-textarea rows="6" v-model="candidacy.body" placeholder="Texte..."></b-form-textarea>
        </b-form-group>
        <b-form-group label="CV">
          <upload-file height="100px" :preview="false" v-model="candidacy.resume" />
        </b-form-group>
        <b-form-group label="Lettre de motivation">
          <upload-file height="100px" :preview="false" v-model="candidacy.motivation" />
        </b-form-group>
        <div class="center">
          <b-btn @click="createCandidacy()" variant="primary">Créer la candidature</b-btn>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import CREATE_CANDIDACY from "@/api/mutations/Candidacy/createCandidacy.graphql";
export default {
  data() {
    return {
      candidacy: {},
      jobId: this.$route.params.jobId
    };
  },
  methods: {
    createCandidacy() {
      client
        .mutate({
          mutation: CREATE_CANDIDACY,
          variables: {
            candidacyInput: this.candidacy,
            jobId: this.jobId
          }
        })
        .then(res => {
          if (res.data.createCandidacy.ok) {
            this.$store.dispatch("pushNotif", {
              type: "success",
              message: "Candidature enregistrée"
            });
            setTimeout(() => {
              this.$router.push(`/candidacies/${res.data.createCandidacy.candidacy.id}`);
            }, 3000);
          } else {
           this.$store.dispatch("pushNotif");
          }
        })
        .catch(err => {
         this.$store.dispatch("pushNotif");
          console.log(err);
        });
    }
  },
  computed: {
    jobs() {
      return this.$store.getters.jobs();
    }
  },
  mounted() {
    /*this.$store.commit("SET_CTA", {
      icon: "archive",
      text: "Annuler la création",
      type: "error",
      click: () => {
        this.$router.push("/societies");
      }
    });*/
  }
};
</script>

<style>
</style>
