<template>
  <div id="global-stats">
    <ul v-if="stats">
      <li>
        <h3 class="text-info">Offres publiées</h3>
        <p class="text-info" v-html="stats.jobs"></p>
      </li>
      <li>
        <h3 class="text-primary">Candidatures</h3>
        <p class="text-primary" v-html="stats.candidacies"></p>
      </li>
      <li>
        <h3 class="text-success">Candidatures acceptées</h3>
        <p class="text-success" v-html="stats.acceptedCandidacies"></p>
      </li>
    </ul>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import GLOBAL_STATS from "@/api/queries/Stats/globalStats.graphql";

export default {
  data() {
    return {
      stats: null
    };
  },
  methods: {
    async fetchStats() {
      let res = await client.query({
        query: GLOBAL_STATS
      });
      this.stats = res.data.globalStats;
    }
  },
  mounted() {
    this.fetchStats();
  }
};
</script>

<style lang="scss">
#global-stats {
  border-radius: 8px;
  box-shadow: $box-shadow;
  position: relative;
  background-color: #fff;
  padding:20px 0;
  > ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    > li {
      display: block;
      text-align: center;
      h3 {
          margin-bottom:1em;
      }
      p {
          margin:0;
          font-size:4rem;
      }
    }
  }
}
</style>
