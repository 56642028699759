<template>
  <div id="societies">
    <v-client-table v-if="societies" class="etalent-table" :options="options" name="societiesTable" :data="societies" :columns="columns">
      <div slot="name" slot-scope="props">
        <div class="has-avatar">
          <div class="table-avatar" :style="setBg(props.row.photo)"></div>
          <router-link :to="`/societies/${props.row.id}`" v-html="props.row.name"></router-link>
        </div>
      </div>
      <div slot="departments" slot-scope="props">
        {{props.row.departments.length}}
      </div>
      <ul class="tools" slot="tools" slot-scope="props">
        <li v-tooltip="'Voir la société'" @click="show(props.row.id)">
          <font-awesome-icon icon="eye" class="icon"></font-awesome-icon>
        </li>
        <li v-tooltip="'Modifier la société'" @click="edit(props.row.id)">
          <font-awesome-icon icon="edit" class="icon"></font-awesome-icon>
        </li>
        <li v-tooltip="'Supprimer la société'" @click="deleteSociety(props.row.id)">
          <font-awesome-icon icon="trash-alt" class="icon"></font-awesome-icon>
        </li>
      </ul>
    </v-client-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newSociety: {},

      options: {
        filterByColumn: true,
        filterable: ["name"],
        headings: {
          name: "Nom",
          departments: "Nombre de départements",
          tools: "Outils"
        }
      }
    };
  },
  methods: {
    async deleteSociety(societyId) {
      try {
        let res = await this.$store.dispatch("deleteSociety", societyId);
        this.$store.dispatch("pushNotif", {
          type: "success",
          message: "La société a bien été supprimée"
        });
      } catch (e) {
        this.$store.dispatch("pushNotif", {
          type: "error",
          message: "Impossible de supprimer la société"
        });
      }
    },
    edit(id) {
      this.$router.push(`/societies/${id}/edit`);
    },
    show(id) {
      this.$router.push(`/societies/${id}`);
    }
  },
  mounted() {
    this.$store.dispatch("fetchSocieties");
    this.$store.commit("SET_CTA", {
      icon: "plus-square",
      text: "Nouvelle société",
      roles: ["admin"],
      click: () => {
        this.$router.push("/create/society");
      }
    });
  },
  computed: {
    columns() {
      return ["name", "departments", ...(this.isAdmin ? ["tools"] : [])];
    },
    isAdmin() {
      return this.$store.getters.isAdmin();
    },
    societies() {
      return this.$store.getters.societies();
    }
  }
};
</script>

<style lang="scss">
#societies {
}
</style>
