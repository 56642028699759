<template>
  <ul class="stars-form">
    <li @click="setScore(i)" v-for="i in [1,2,3,4,5]" :key="i">
      <font-awesome-icon v-tooltip="`${i}/5`" :style="{fontSize: size}" class="icon" :icon="[score >= i ? 'fas' : 'far', 'star']"></font-awesome-icon>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    size: {
        type: String,
        default: "initial"
    }
  },
  data() {
      return {
          score: 0,
      }
  },
  methods: {
      setScore(score) {
          this.score = score;
          this.$emit('scoreChanged', score)
      }
  }
};
</script>

<style lang="scss">
.stars-form {
    display:flex;
    align-items: center;
    justify-content: center;
    margin:0;
    padding:0;
    > li {
        cursor:pointer;
        .icon {
            color:#e7711b !important;
        }
    }
}
</style>
