<template>
  <div id="folder">
    <CandidaciesList :candidacies="candidacies" />
  </div>
</template>

<script>
import CandidaciesList from "@/components/Candidacy/CandidaciesList";
export default {
  components: {
    CandidaciesList
  },
  data() {
    return {
      id: this.$route.params.id
    };
  },
  computed: {
    folder() {
      return this.$store.getters.folder();
    },
    candidacies() {
      return this.$store.getters.candidacies();
    }
  },
  methods: {
    fetchCandidacies(offset, folderId) {
      this.$store.dispatch("fetchCandidacies", { offset, folderId: this.id });
    }
  },
  mounted() {
    this.fetchCandidacies(0, this.id);
    this.$store.commit("SET_CTA", {
      icon: "trash",
      text: "Supprimer ce dossier",
      click: () => {
        this.$store.dispatch("deleteFolder", this.id).then(res => {
          if (res) {
            this.$router.push("/folders");
          }
        });
      }
    });
  },
  destroyed() {
    this.$store.commit("RESET_CANDIDACIES");
  }
};
</script>

<style>
</style>
