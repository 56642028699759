<template>
  <transition name="modal-fade">
    <div @click.stop="close()" class="modal-mask">
      <div @click.stop class="modal">
        <div class="modal-close">
          <font-awesome-icon @click="close()" icon="times" />
        </div>
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  },
  computed: {}
};
</script>

<style lang="scss">
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.modal-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background: #ffffff;
  box-shadow:  0px 3px 7px 3px rgba(0, 0, 0, 0.3);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  width:800px;
  max-width:85%;
  min-height:30vh;
  position:relative;
  border-radius:2px;
  .modal-close {
      position:absolute;
      top:10px;
      right:15px;
      opacity:0.4;
      color:#444;
      cursor:pointer;
  }
}
</style>
