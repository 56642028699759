<template>
    <div id="candidacies">
        <CandidaciesList :candidacies="candidacies" @refetchCandidacies="refetch" />
    </div>
</template>
  
  <script>
import CandidaciesList from "@/components/Candidacy/CandidaciesList";
export default {
    components: {
        CandidaciesList
    },
    data() {
        return {
            id: this.$route.params.id
        };
    },
    computed: {
        candidacies() {
            return this.$store.getters.candidacies().filter((candidacy) => {
                return candidacy.tags.find((tag) => {
                    return parseInt(tag.id) === parseInt(this.id)
                })
            })
        }
    },
    methods: {
        fetchCandidacies(offset) {
            this.$store.dispatch("fetchCandidacies", { offset });
        },
        refetch() {
            this.$store.commit("RESET_CANDIDACIES");
            this.$store.dispatch("fetchCandidacies", { offset: 0 });
        }
    },
    mounted() {
        this.fetchCandidacies(0);
    },
    destroyed() {
        this.$store.commit("RESET_CANDIDACIES");
    }
};
</script>
  
  <style lang="scss">
  </style>