import { client } from '@/apollo/init.js';

import ORGANIZATION from "@/api/queries/Organization/organization.graphql";
import REGISTER_IN_ORGANIZATION from "@/api/mutations/User/registerInOrganization.graphql";

const defaultState = {
    organization: null
};

const actions = {
    fetchOrganization: (context) => {
        context.commit('UPDATE_LOADING', 1);
        return client.query({
            query: ORGANIZATION
        }).then(res => {
            context.commit('UPDATE_LOADING', -1);
            context.commit('SET_ORGANIZATION', res.data.organization)
        }).catch(e => {
            context.commit('UPDATE_LOADING', -1);
            console.log(e)
        })
    },
    registerInOrganization: (context, userInput) => {
        context.commit('UPDATE_LOADING', 1);
        return client.mutate({
            mutation: REGISTER_IN_ORGANIZATION,
            variables: {
                userInput
            }
        }).then(res => {
            context.commit('UPDATE_LOADING', -1);
            let data = res.data.registerInOrganization
            if (data.ok) {
                context.commit('PUSH_USER', data.user)
                return data
            }
        }).catch((e) => {
            context.commit('UPDATE_LOADING', -1);
            console.log(e);
        })
    },
};

const mutations = {
    SET_ORGANIZATION: (state, payload) => {
        state.organization = payload
    },
    PUSH_USER: (state, user) => {
        state.organization.users.push(user)
    }
};

const getters = {
    organization: state => () => state.organization,
};

export default {
    state: defaultState,
    getters,
    actions,
    mutations,
};
