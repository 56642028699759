<template>
  <div class="comments">
    <card >
      <template slot="header">
        <font-awesome-icon class="icon" icon="comments"></font-awesome-icon>
        <h2>Commentaires</h2>
      </template>
      <div v-if="comments" class="comments-list" v-bar>
        <ul ref="comments">
          <li v-for="comment in comments" :key="comment.id">
            <Comment :comment="comment" />
          </li>
        </ul>
      </div>
      <div v-else>
        loading
      </div>
      <form @submit.prevent="createComment()">
        <textarea placeholder="Ecrivez votre commentaire" v-model="newComment.body"></textarea>
        <button type="submit">
          <font-awesome-icon class="icon" icon="plus"></font-awesome-icon>
        </button>
      </form>
    </card>

  </div>
</template>

<script>
import Comment from "@/components/Comment/Comment";
export default {
  components: {
    Comment
  },
  data() {
    return {
      newComment: {},
      currentEdit: null
    };
  },
  props: {
    comments: {
      type: Array,
      default: null
    },
    candidacyId: {
      type: String,
      default: null
    }
  },
  methods: {
    createComment() {
      this.$store
        .dispatch("createComment", {
          commentInput: this.newComment,
          candidacyId: this.candidacyId
        })
        .then(res => {
          if (res) {
            this.newComment = {};
            this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
          }
        });
    },
    cancelEdit(old) {
      this.currentEdit = null;
    },
    editComment(id) {
      this.currentEdit = id;
    }
  },
  mounted() {
    this.$refs.comments.scrollTop = this.$refs.comments.scrollHeight;
  }
};
</script>

<style lang="scss">
.comments {
  .comments-list {
    height: 280px;
    ul {
      > li {
        //padding:10px 15px;
        background: #eee;
        &:nth-child(2n) {
          background-color: #fff;
        }
      }
    }
  }
  form {
    display: flex;
    border-top: 1px solid #ddd;
    textarea {
      flex: 1;
      padding: 12px 17px;
      margin: 0;
      border: none;
    }
    button {
      padding: 5px 15px;
      border: none;
      color: #fff;
      background: $success;
      &:hover {
        background: $success-D;
      }
    }
  }
}
</style>
