<template>
  <div id="latest-jobs">
    <card>
      <template slot="header">
        <font-awesome-icon class="icon" icon="comments"></font-awesome-icon>
        <h2>Publications récentes</h2>
      </template>
      <ul class="list">
        <li v-for="job in latestJobs" :key="job.id">
          <div>
            <Avatar v-if="job.user" :img="job.user.imgUrl" />
          </div>
          <router-link :to="`/jobs/${job.id}/candidacies`" v-html="job.title"></router-link>
          <p class="subinfo"> crée {{job.created_at | moment("from", "now")}}</p>
        </li>
      </ul>
    </card>
  </div>
</template>

<script>
import { client } from "@/apollo/init.js";
import JOBS from "@/api/queries/Job/jobs.graphql";
export default {
  data() {
    return {
      latestJobs: null
    };
  },
  methods: {
    async fetchLatestJobs() {
      let res = await client.query({
        query: JOBS,
        variables: {
          limit: 3,
          offset: 0,
          closed: false
        }
      });
      this.latestJobs = res.data.jobs;
    }
  },
  mounted() {
    this.fetchLatestJobs();
  }
};
</script>

<style lang="scss">

</style>
