<template>
  <div class="login">
    <form @submit="login()">
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="user"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input v-model="form.email" placeholder="Indiquez votre adresse email" required></b-form-input>
      </b-input-group>
      <Spacer />
      <b-input-group>
        <b-input-group-prepend is-text>
          <font-awesome-icon icon="unlock"></font-awesome-icon>
        </b-input-group-prepend>
        <b-form-input type="password" v-model="form.password" placeholder="Indiquez votre mot de passe" required></b-form-input>
      </b-input-group>
      <Spacer :lvl="1" />
      <a href="#" @click.prevent="$router.push('/recoverPassword')">Mot de passe oublié ?</a>
      <div class="my-4 flex">
        <Btn class="mx-3" shadow radius="4px" padding="18px 30px" @click.native="login()">
          <font-awesome-icon v-if="loading" class="icon" icon="spinner" spin></font-awesome-icon>
          <span v-else>Se connecter</span>
        </Btn>
      </div>
      <div v-html="error" class="error" v-if="error"></div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      error: null,
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    login() {
      this.loading = true;
      this.$store
        .dispatch("login", {
          email: this.form.email,
          password: this.form.password
        })
        .then(login => {
          this.loading = false;
          if (login.ok) {
            this.$router.push(this.$route.query.to || "/");
          } else {
            this.error = login.errors[0].message;
          }
        })
        .catch(e => {
          this.loading = false;
          this.error = "Erreur lors de la connexion. Merci de réessayer";
        });
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.login {
  background-color: #fff;
  .form-header {
    text-align: center;
    padding: 1.3em;
    background-color: $lightgrey;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
  }
}
</style>
