<template>
  <div id="logout">

  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("logout");
  }
};
</script>

<style>
</style>
