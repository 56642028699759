function getServerUrl() {
    return process.env.BDD === 'production' ? 'http://rh.adventuregroup.fr' : 'http://localhost:8082'
}

const ServerUrl = {
    install(Vue, options) {
        Vue.prototype.$serverUrl = getServerUrl()
    }
};

export default ServerUrl
